const constant = Object.freeze({
  COOKIE_PREMIUM_USER_FREE: "COOKIE_PREMIUM_USER_FREE",
  COOKIE_COUNTRY: "COOKIE_COUNTRY",
  COOKIE_AUTH: "_AUTH",
  COOKIE_AUTH_REFRESH: "_AUTH_REFRESH",
  COOKIE_GUEST: "_GUEST",
  COOKIE_USER: "_USER",
  COOKIE_LANG: "COOKIE_LANG",
  COOKIE_AFFILIATE: "COOKIE_AFFILIATE",
  ROUTES: {
    FAVORITES: {
      ALL: "all",
      LIST: "list",
    },
    PROFILE: {
      SUBSCRIPTION: "subscription",
      PREFERENCES: "preferences",
      HISTORY: "history",
      WAVE_ALERT: "wave-alerts",
      REFERRALS: "referrals",
      BENEFITS: "benefits",
      USER_PROFILE: "profile",
      OPTIONS: "options"
    },
  },
  SUBSCRIPTION: {
    FREE: "1",
    MONTHLY: "2",
    ANNUALLY: "3",
    REFILL: "4",
    ESPECIAL_DAY: "5",
  },
  STREAMING_EVENT_TYPE: {
    start: "onStart",
    play: "onPlay",
    progress: "onProgress",
    pause: "onPause",
    maxFreeTime: 18,
    maxAFK: 180,
  },
  SPONSORS: {
    SCREEN_POSITION_1: {
      id: "1",
      style: "screen-position-1",
      height: "90px",
    },
    SCREEN_POSITION_2: {
      id: "2",
      style: "screen-position-2",
      height: "500px",
    },
    SCREEN_POSITION_3: {
      id: "3",
      style: "screen-position-3",
      height: "200px",
    },
    SCREEN_POSITION_4: {
      id: "4",
      style: "screen-position-4",
      height: "130px",
    },
    SCREEN_POSITION_5: {
      id: "5",
      style: "screen-position-5",
      height: "110px",
    },
    SCREEN_POSITION_6: {
      id: "6",
      style: "screen-position-6",
      height: "150px",
    },
  },
  ADD_ROTATE_TIME: 15000,
  FEAUTRE_CAMEREWIND: "camrewind",
  FEATURE_MULTISPOT: "multispot",
  FEATURE_MULTISPOT_TOP: "multispot_top",
  VERIFICATION_ACCOUNT_STATUS: {
    PENDING_ACTIVATION: "PENDING_ACTIVATION",
    ACTIVE: "ACTIVE",
  },
  AVAILABLE_COUNTRIES: {
    AR: "Argentina",
    BR: "Brasil",
    CL: "Chile",
    CO: "Colombia",
    CR: "Costa Rica",
    PE: "Perú",
    SV: "El Salvador",
    UY: "Uruguay",
    DEFAULT: "Uruguay",
  },
  NPS_TYPE: {
    INTERPRETER: "INTERPRETER",
    WEBAPP: "WEBAPP",
  },
  ABBREVIATIONS: {
    Argentina: "AR",
    Brasil: "BR",
    Chile: "CL",
    Colombia: "CO",
    "Costa Rica": "CR",
    "Perú": "PE",
    "El Salvador": "SV",
    Uruguay: "UY"
  },
  STATUS: {
    LOGGED: "logged",
    DELETED: "DELETED"
  },
  VALIDATE_DAY: {
    camerewind_start: "2022-12-30T00:00:00",
    camerewind_end: "2023-01-21T23:59:59",
  },
  MAX_WAVE_ALERT: {
    premium: 3,
    free: 1,
  },
  CAMPAIGN_TYPE: {
    PREMIUM: "PREMIUM",
    REGISTER: "REGISTER",
  },
  TAGS: [
    { name: "wizzard_step_5_sport_1", value: "SURFING" },
    { name: "wizzard_step_5_sport_2", value: "KITE" },
    { name: "wizzard_step_5_sport_3", value: "FOIL" },
    { name: "wizzard_step_5_sport_4", value: "WINDSURF" },
    { name: "wizzard_step_5_sport_5", value: "NATACION" },
    { name: "wizzard_step_5_sport_6", value: "PESCA" },
    { name: "wizzard_step_5_sport_7", value: "SUP" },
    { name: "wizzard_step_5_sport_other", value: "OTHER" },
  ],
  MONTHS_ANNUAL_PLAN: 12,
  ONEILL_DISCOUNT: {
    FREE: 0,
    PREMIUM: 20,
  },
  DAYS: {
    es: {
      0: "domingo",
      1: "lunes",
      2: "martes",
      3: "miércoles",
      4: "jueves",
      5: "viernes",
      6: "sábado",
    },
    pt: {
      0: "domingo",
      1: "Segunda-feira",
      2: "Terça-feira ",
      3: "Quarta-feira",
      4: "Quinta-feira ",
      5: "Sexta-feira",
      6: "sábado",
    }
  },

  MONTHS: {
    es: {
      0: "enero",
      1: "febrero",
      2: "marzo",
      3: "abril",
      4: "mayo",
      5: "junio",
      6: "julio",
      7: "agosto",
      8: "setiembre",
      9: "octubre",
      10: "noviembre",
      11: "diciembre"
    },
    pt: {
      0: "Janeiro",
      1: "Fevereiro",
      2: "Março",
      3: "Abril",
      4: "Maio",
      5: "Junho",
      6: "Julho",
      7: "Agosto",
      8: "Setembro",
      9: "Outubro",
      10: "Novembro",
      11: "Dezembro"
    }
  },
  SPOT_TYPE: {
    BEACH: 'beach',
    LAKE: 'lake'
  },
  ACCESS_METHOD: {
    LINEUP: 'Lineup',
    GOOGLE: 'Google',
    APPLE: 'Apple',
    FACEBOOK: 'Facebook'
  },
  COUNTRIES_LIST: [
    { value: "Argentina", label: "Argentina" },
    { value: "Brasil", label: "Brasil" },
    { value: "Chile", label: "Chile" },
    { value: "Colombia", label: "Colombia" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Perú", label: "Perú" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Uruguay", label: "Uruguay" }
  ],
  FORCAST_TIME: {
    MORNING: "morning",
    MIDDAY: "midday",
    AFTERNOON: "afternoon", 
  },
  FORECAST_DAYS_FREE_USER: 3,
  FORCASTERS:{
    UY: {
      name: "Fabio Cicchini", 
      src: require("../assets/forecasters/forecaster-fabio.png"), //Si se llegase a cambiar la ubicacion del forecasterComponent, se deberia de revisar si se sigue apuntando a la imagen desde la misma ruta.
      bio: "https://noticias.lineup.surf/2023/09/07/fabio-cicchini/",
    },
    AR: {
      name: "Hernan Carro", 
      src: require("../assets/forecasters/forecaster-hernan.jpg"),
      bio: "https://noticias.lineup.surf/2024/05/30/hernan-carro/",
    },
    BR: {
      name: "Luiz Guilherme Aguiar",
      src: require("../assets/forecasters/forecaster_Luiz_Guilherme.jpeg"),
      bio: "https://noticias.lineup.surf/2024/06/27/luiz-guilherme-aguiar/",
    }
  },
  FORCASTERS_COUNTRIES: {
    AR: "Argentina",
    BR: "Brasil",
    UY: "Uruguay",
    DEFAULT: "Uruguay",
  }, //modify these when add a new forecaster.
  APPLE_ACCOUNT: [
    "store.apple.stg@lineup.surf",
    "store.apple@lineup.surf",
    // "your_mail@lineup.surf", // set your account for local testing
  ],
  APPLE_DOMAINS: [
    "ios.lineup.surf",
    "ios-stg.lineup.surf",
    // "localhost:3000", // for local testing
  ]
});

export default constant;

export const whatsappComunnityLink = "https://chat.whatsapp.com/FJlPHa4cNuK9GY4txpfwT3";
