import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import SearchBarComponent from "../components/searchBarComponent";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Animate from "react-smooth/lib/Animate";
import { useTranslation } from "react-i18next";
import utils from "../utils/utils";
import constant from "../utils/constant";
import { ImNewspaper } from "react-icons/im"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FaBookmark } from "react-icons/fa";

const NavbarMobileComponent = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const logosvg = require('../assets/logo/logo-profile.svg');

  const profileImageUrl = props.user?.profileImageUrl == "" ? logosvg : props.user.profileImageUrl;


  return (
    <NavbarMobileContainer>
      <NavMobileHeaderContainer>
          <Link to="/" id="gtm-navbar-mobile-logo">
            <NavLogoMobile src={require("../assets/icons/logo.svg")} width="600" height="400" />
          </Link>
        <SearchBarComponent />
      </NavMobileHeaderContainer>

      <NavMobileContainer className={props.status === "logged" && props.user.isPremium === false ? "all-navbar" : "small-navbar"}>
        <NavMobileItem className={location && location.pathname === "/home" ? "active" : ""}>
          <Link to="/" id="gtm-navbar-mobile-home">
            {" "}
            <NavMobileImg src={require("../assets/icons/logo.svg")} />
            <NavbarMobileText>{t("nav_home")}</NavbarMobileText>
          </Link>
        </NavMobileItem>
        <NavMobileItem className={utils.isUserInUrl(location, "spots") === true ? "active" : ""}>
          <Link to={utils.getGenericSpotLink(constant.AVAILABLE_COUNTRIES[props.user.country])} id="gtm-navbar-mobile-spots">
            {" "}
            <NavMobileImg src={require("../assets/icons/spot.svg")} />
            <NavbarMobileText>{t("nav_spots")}</NavbarMobileText>
          </Link>
        </NavMobileItem>

        {props.user && props.user.isPremium !== true ? (
          <NavMobileItem className={utils.isUserInUrl(location, "premium") === true ? "active" : ""}>
            <Link to="/premium" id="gtm-navbar-mobile-multispot">
              {" "}
              <NavMobileImg src={require("../assets/icons/pricing-icon.svg")} />
              <NavbarMobileText>{t("nav_plans_button")}</NavbarMobileText>
            </Link>
          </NavMobileItem>
        ) : (
          <>
            <NavMobileItem className={utils.isUserInUrl(location, "favorites") === true ? "active" : ""}>
              <Link to="/favorites" id="gtm-navbar-mobile-multispot">
                {" "}
                <NavBarMobileSvgContainer> <FaBookmark color='#425781' /> </NavBarMobileSvgContainer>
                <NavbarMobileText>{t("nav_multispot_button")}</NavbarMobileText>
              </Link>
            </NavMobileItem>
          </>
        )}

        <NavMobileItem className={utils.isUserInUrl(location, "notifications") === true ? "active" : ""}>
          <Link to="/notifications" id="gtm-navbar-mobile-notifications">
            {" "}
            <span className="navMobile-notificationsCounter" style={{ display: props.unreadNotificationsCounter > 0 ? 'block' : 'none' }}>
              { props.unreadNotificationsCounter > 99 ? ('+99') : (props.unreadNotificationsCounter) }
            </span>
            <FontAwesomeIcon icon={faBell} className="navMobile-notificationsButton" />
            <NavbarMobileText>{t("nav_notifications_button")}</NavbarMobileText>
          </Link>
        </NavMobileItem>

        {props.status === "logged" ? (
          <React.Fragment>
            <NavMobileItem className={ utils.isUserInUrl(location, "account") === true || utils.isUserInUrl(location, "options") === true ? "active" : ""}>
              <Link to={props.user.status === constant.VERIFICATION_ACCOUNT_STATUS.PENDING_ACTIVATION ? "/account/preferences" : "/options"} id="gtm-navbar-mobile-account">
                <NavMobileImg className="round" src={profileImageUrl} />
                <NavbarMobileText>{t("nav_account_button")}</NavbarMobileText>
              </Link>
            </NavMobileItem>
          </React.Fragment>
        ) : (
          <NavMobileItem className="register-button profile-button">
            <Link to="/access" id="gtm-navbar-mobile-access">
              <NavMobileImg className="user-icon" src={require("../assets/icons/user.svg")} />
              <NavbarMobileText>{t("nav_account_button")}</NavbarMobileText>
            </Link>
          </NavMobileItem>
        )}
      </NavMobileContainer>
    </NavbarMobileContainer>
  );
};


const NavbarMobileText = styled.span`
font-size: 0.7em;
font-family: Poppins;
color: #425781;
font-weight: 500;
text-decoration: none;
`;

const NavbarMobileContainer = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }

  svg{
    width: 32px;
    height: 32px;
  }

  .all-navbar{
    li{
      padding: 3px !important;
      padding-top: 9px !important;
    }
  }

`;

const NavMobileHeaderButton = styled.div`
  text-align: center;
  width: 100%;
  background-color: #31c241;
  padding: 10px;
  font-family: "Poppins";
  font-size: 0.9em;
  margin-bottom: 0;
  border: none;

  a {
    color: #fff;
  }
`;

const NavMobileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const NavLogoMobile = styled.img`
  width: 60px;
  margin: 20px;
  margin-right: 10px;
`;

const NavMobileContainer = styled.ul`
  display: inline-flex;
  text-align: center;
  text-decoration: none;
  margin: auto;
  height: 70px;
  margin-bottom: 0 !important;
  padding: 0;
  width: 100%;
  position: fixed;
  z-index: 999999;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #00000014;
  display: grid;
  grid-auto-flow: column;

  .active {
    border-color: #274e87 !important;
  }

  .register-button {
    background-color: rgb(255, 165, 60);
    border-radius: 3px;
    color: #fff !important;


    img {
  @media (min-width: 620px) {
    margin-left: 5px;
    margin-bottom: 5px;
  }
  }

    img{
      width: 30px !important;
    }


    span{
      color: #fff !important;
    }
  }
`;

const NavMobileItem = styled.li`
  padding: 17px;
  list-style-type: none;
  border-top: 2px solid;
  border-image-source: none;
  border-color: transparent;
  border-image-slice: 20;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  display: table-cell;
  vertical-align: middle;
  padding-top: 8px;

  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  @media (max-width: 360px) {
    padding: 8px !important;
    margin-bottom: -10px !important;
  }

  @media (max-width: 320px) {
    padding: 12px !important;
    padding-top: 8px !important;
    margin-bottom: -10px !important;
  }

  svg{
    @media (min-width: 450px) {
      width: 42px;
      height: 42px;
    }
  }

  a,span {
    text-decoration: none;
    
  @media (min-width: 620px) {
    display: grid;
    text-align: center;
    justify-content: center;
  }
  }

  button {
    background-color: transparent;
    border: none;

    img {
      margin-bottom: 120px;
    }
  }

  span {
    @media (max-width: 360px) {
      margin-bottom:  5px;
    }
  }

  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  svg{
    color: #425781;
  }

  .user-icon {
    width: 32px !important;
  }

  .round{
    border-radius: 50% !important;
  }
`;

const NavMobileImg = styled.img`
  margin-left: 1px;
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  @media (min-width: 450px) {
    width: 42px;
    height: 42px;
  }
`;

const NavBarMobileSvgContainer = styled.div`
svg{
  width: 21px;
  margin-left: 1px;
  @media (min-width: 450px) {
    width: 42px;
  }
}
`

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(NavbarMobileComponent);
