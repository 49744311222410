import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import utils from "../utils/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import constant from "../utils/constant";

const FooterComponent = (props) => {
  const {isAppleUser} = props;
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(utils.isUsingMobile());
  const [isProfileView, setIsProfileView] = useState(false);
  const [instagramLink, setInstagramLink] = useState('https://www.instagram.com/lineup.surf/');

  useEffect(() => {
    if (window.location && window.location.pathname && window.location.pathname.includes("account")) {
      setIsProfileView(true);
    } else {
      setIsProfileView(false);
    }

    // Set instagram link
    if(props.user.country === 'BR'){
      setInstagramLink("https://www.instagram.com/lineup.surf.br/")
    }
  });

  return (
    <>
      {(isMobile === false || (isMobile === true && isProfileView === true)) && (
        <footer>

          <div className="container-xl">
            <div className="footer-inner">
              <div className="row d-flex align-items-center gy-4">
                {/* copyright text */}
                <div className="col-md-3">
                  <Link to="/" id="gtm-footer-logo">
                    <LogoFooter src={require("../assets/logo/logo.svg")} alt="logo" />
                  </Link>
                  <BrandText className="copyright">© {utils.getYear()} Lineup (<VersionContainer>V{process.env.REACT_APP_VERSION}</VersionContainer>)</BrandText>
                </div>
                {/* social icons */}
                <IconList className="col-md-3" style={{marginBottom: "auto"}}>
                <div>
                <IconTitle>{t("icon_text_footer")}</IconTitle>
                    <a href={instagramLink} target="_blank" >
                      <img src={require('../assets/icons/instagram.png')}></img>
                    </a>
                    <a href={"https://www.youtube.com/@lineupsurfing"} target="_blank" >
                      <img src={require('../assets/icons/youtube.png')}></img>
                    </a>
                    <a href={"https://www.tiktok.com/@lineup.surf"} target="_blank" >
                      <img src={require('../assets/icons/tiktoklogo.webp')}></img>
                    </a>
                    <a href={"https://www.linkedin.com/company/lineupsurf/"} target="_blank" >
                      <img src={require('../assets/icons/linkedin.png')}></img>
                    </a>
                  </div>
                </IconList>
                {/* go to top button */}
                <LinksContainer className="col-md-6">
                  <LinksList>
                    <li className="nav-item" id="gtm-footer-home">
                      <Link to="/">{t("footer_home_link")}</Link>
                    </li>
                    <li className="nav-item">
                      <Link to={utils.getGenericSpotLink(constant.AVAILABLE_COUNTRIES[props.user.country])} id="gtm-footer-spots">{t("footer_spots_link")}</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/favorites" id="gtm-footer-spots">{t("nav_multispot_button")}</Link>
                    </li>
                    <li className="nav-item">
                      <a href="https://noticias.lineup.surf" id="gtm-footer-spots">{t("nav_news_button")}</a>
                    </li>
                    { !isAppleUser && 
                      <li className="nav-item">
                        <Link to="/premium" id="gtm-footer-spots">{t("nav_plans_button")}</Link>
                      </li>
                    }
                  </LinksList>
                  <LinksList>
                    { !isAppleUser && 
                      <li className="nav-item">
                        <Link id="gtm-footer-register" to="/premium">{t("footer_register_link")}</Link>
                      </li>
                    }
                    <li className="nav-item">
                      <Link id="gtm-footer-login" to={{ pathname: "/access", state: { prevPath: window.location.pathname, isRegister: false } }}>{t("footer_login_link")}</Link>
                    </li>
                  </LinksList>
                  <LinksList>
                    <li>
                      <a href="https://noticias.lineup.surf/2023/06/14/preguntas-frecuentes-faqs-lineup/" >
                        {t("footer_FAQs_link")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link id="gtm-footer-terms-of-use" to="/terms-of-use">{t("footer_conditions_link")}</Link>
                    </li>
                    <li className="nav-item">
                      <Link id="gtm-footer-privacy-policy"  to="/privacy-policy">{t("footer_privacy_link")}</Link>
                    </li>
                  </LinksList>
                  <SocialIconsFooter className="social-icons list-unstyled list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/lineup.surf.everywhere" target="_blank" rel="noopener noreferrer" id="gtm-footer-facebook" >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/lineup.surf/" target="_blank" rel="noopener noreferrer" id="gtm-footer-instagram" >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </SocialIconsFooter>
                </LinksContainer>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

const LinksContainer = styled.ul`
  display: flex;
  text-align: left;

  @media (max-width: 1000px) {
    display: contents;
  }
`;

const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-right: 40px;

  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    margin-left: 20px;
    text-align: center;
    margin-right: 0px;
    li {
      margin-top: 10px;
      margin-right: 0px !important;
    }
  }

  li {
    margin-right: 20px;

    a {
      font-size: 0.8em;
      font-family: "Poppins";
      color: #004989;
      font-weight: 400;
    }

    button {
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
      border: none;
      font-size: 0.8em;
      font-family: "Poppins";
    }
  }
`;

const LogoFooter = styled.img`
  width: 150px;
`;

const BrandText = styled.p`
  margin-top: 15px;
  margin-left: 10px;
`;

const VersionContainer = styled.span`
  text-align: left;
  font-size: 0.6em;
  font-family: Poppins;
  color: #a5a5a5;
`;

const SocialIconsFooter = styled.ul`
 position: absolute;
  right: 20px;

  a{
    font-size: 1.4em !important;
  }

  @media (max-width: 1000px) {

  right: 20px;
    text-align: center !important;
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
    position: static !important;
  }
`;

const IconList = styled.div`
  display: flex;

  img{
    margin: 0 8px;
    width: 36px;
    height: auto;
    cursor: pointer;
  }

  @media(max-width: 999px){
    justify-content: center;
  }
`

const IconTitle = styled.p`
  color: #203656;
  margin-left: 8px;
  font-weight: 500;
  font-size: 18px;
`

const mapStateToProps = (state) => ({
  user: state.user,
  isAppleUser: state.isAppleAccount,
})

export default connect(mapStateToProps)(FooterComponent);
