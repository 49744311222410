import AdManager from '../../components/adManager';
import { Divider, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Animate from "react-smooth/lib/Animate";
import StarRating from "react-svg-star-rating";
import FooterComponent from "../../components/footerComponent";
import LoaderComponent from "../../components/LoaderComponent";
import MapComponent from "../../components/mapComponent";
import spotService from "../../modules/spotService";
import constant from "../../utils/constant";
import utils from "../../utils/utils";
import "./spots.scss";
import styled from "styled-components";
import DropDownMenu from "../../components/dropDownMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWind, faWater } from '@fortawesome/free-solid-svg-icons';

const Spots = (props) => {
  const { user, country: countryProp, currentTime } = props;
  const { countryParam = user.country === undefined ? constant["AVAILABLE_COUNTRIES"]["DEFAULT"] : constant["AVAILABLE_COUNTRIES"][user.country], orientationParam = "ALL" } = useParams();
  const { t, i18n } = useTranslation();
  const [spots, setSpots] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [country, setCountry] = useState(constant["AVAILABLE_COUNTRIES"][countryProp]);
  const [orientation, setOrientation] = useState("All");
  const [auxSpots, setAuxSpots] = useState(null);
  const [isMap, setIsMap] = useState(false);
  const [countryData, setCountryData] = useState({cams: 0, forecasts: 0});
  const spotIdsLakes = ["1001", "3600", "1016", "1220", "404", "1200","1000","1002","1210"];
 
  const RatingBox = styled.span`` // Force rendering of stars (refreshes forecasts without stars)
  const cardinalPoints = [
    { value: "ALL", label: t("filter_all_spots") },
    { value: "N", label: "N" },
    { value: "NE", label: "NE" },
    { value: "E", label: "E" },
    { value: "SE", label: "SE" },
    { value: "S", label: "S" },
    { value: "SO", label: "SO" },
    { value: "O", label: "O" },
    { value: "NO", label: "NO" },
  ];
  const history = useHistory();
  const defaultOrientation = orientation === "ALL";
  
  useEffect(() => {
    if (spots === null || user.country === undefined) {
      let currentCountry = constant["AVAILABLE_COUNTRIES"][countryProp];
      if (currentCountry === undefined) {
        currentCountry = constant["AVAILABLE_COUNTRIES"]["DEFAULT"];
      }
      setCountry(currentCountry);
      getSpots();
    }

    if (countryParam !== "undefined" && countryParam !== "null") {
      setCountry(utils.capitalizeFirstLetter(countryParam));
    }
    if (orientationParam !== undefined && orientationParam !== null) {
      setOrientation(orientationParam.toUpperCase());
    }
  }, []);

  const getSpots = async () => {
    const data = await spotService.getSpots("", false, true, true);
    setSpots(utils.orderSpots(data));
    setAuxSpots(data);
    setIsLoading(false);
  };

  const filterSpotsByOrientation = (orientationSelected) => {
    const spotsFiletered = [];
    if (orientationSelected === "ALL") {
      setSpots(utils.orderSpots(auxSpots));
    } else {
      for (let index = 0; index < auxSpots.length; index++) {
        const element = auxSpots[index];
        if (utils.getOrientation(element["orientation"]) === orientationSelected) {
          spotsFiletered.push(element);
        }
      }
      setSpots(utils.orderSpots(spotsFiletered));
    }
  };

  const getCountryData = () => {
    if(!defaultOrientation)
      return;

    let cams = spots[country]["recommended"].length;
    let forecasts = 0;
    
    Object.keys(spots[country]["all"]).forEach((cty, ind) => forecasts += spots[country]["all"][cty].values.length);
    setCountryData({cams, forecasts});
  }

  useEffect(() => {
    if (auxSpots !== null) filterSpotsByOrientation(orientation);
  }, [auxSpots]);

  useEffect(() => {
    if (utils.capitalizeFirstLetter(countryParam) !== country && country !== undefined && country !== null && countryParam !== "undefined") {
      setCountry(utils.capitalizeFirstLetter(countryParam));
    }

    if (orientationParam !== "null" && orientationParam !== "undefined") {
      if (auxSpots !== null) {
        filterSpotsByOrientation(orientationParam.toUpperCase());
        setOrientation(orientationParam.toUpperCase());
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    if(!spots)
      return;

    getCountryData();
  }, [spots]);

  const handleCountry = (countrySelected) => {
    history.push(`/spots/country/${countrySelected}/${orientation}`);
  };

  const handleOrientation = (orientationSelected) => {
    setOrientation(orientationSelected);
    history.push(`/spots/country/${country}/${orientationSelected}`);
  };

  const logosvg = require('../../assets/home/free_user_premium.jpg');

  return (
    <div className="site-wrapper" id="spots">
      {isLoading === true ? (
        <LoaderComponent />
      ) : (
        <Animate to="1" from="0" duration={500} attributeName="opacity">

          <Helmet>
            <title>{t("nav_spots")} - {t("seo_title_page")}</title>
            <meta name="description" content={t("meta_description_views_spots")} />
            <meta name="og:description" content={t("meta_description_views_spots")} />
          </Helmet>

          <section className="main-content">
            <div className="container-xl">
              <h1 className="section-title header-title">{t("spots_list_label")}</h1>
              <div className="filter-container">
                
                {/* Country filter */}
                <DropDownMenu title={t("spots_country") + ":"} list={constant.COUNTRIES_LIST} key={"countries-list"} onSelect={handleCountry} state={country}  />

                {/* Cardinal Point filter */}
                <DropDownMenu title={t("filter_spot_text")} list={cardinalPoints} key={"orientation-list"} onSelect={handleOrientation} state={orientation}  />

                {/* List/Map selector */}
                <div className="switch-wrap d-flex map-switch" id="BillingType">
                      <div className="switch-plans ">
                        <span className="display-selector">{t("filter_type_list")}</span>
                        <div className="primary-switch justify-content-center">
                          <label className="switch">
                            <input type="checkbox" id="freqPayment" onChange={() => setIsMap(!isMap)} checked={isMap} />
                            <span className="slider-sub round"></span>
                          </label>
                        </div>
                        <span className="display-selector">{t("filter_type_map")}</span>
                      </div>
                </div>
              </div>

              <Divider style={{ marginBottom: "3%" }} />

              { defaultOrientation && (
                <div className="countryDataContainer">
                  <p className="countryDataText">
                    <Trans
                      i18nKey="country_data_text" // optional -> fallbacks to defaults if not provided
                      values={{ cams: countryData.cams, forecasts: countryData.forecasts, country }}
                      components={{ italic: <i />, bold: <strong /> }}
                    />
                  </p>
                  
                </div>
              )}
              
              <AdManager adUnit="/23206544098/spot-list-filters"/>
                
              { isMap ? (
                <MapComponent spots={spots} country={country} />
              ) : (
                <React.Fragment>
                  {spots !== null ? (
                    spots[country] && Object.keys(spots[country]["all"]).length > 0 ? (
                      Object.keys(spots[country]["all"]).map((key, index) => {
                        return (
                          <div className="gy-4 m-t-20" key={"spot" + index}>
                            <span className="brand-label-department">{spots[country]["all"][key]["name"]}</span>
                            <div className="row">
                              <div className="col-lg-12">
                                <ul className="spot-list-all">
                                  {spots !== null &&
                                    spots[country]["all"][key]["values"].map((spt, index) => {
                                      return (
                                        <Link to={utils.getSpotUrl(spt.country, spt.city, spt.url) + (spt.multicam === true ? "/" + spt.position_camera : "")} key={"spotList" + index} >
                                          {spt.img ? <img src={spt.img} alt={""} /> : <img src={logosvg} alt={""} />}
                                          {spt.surfcam && (
                                            <div className="icon-cam-container" style={{ backgroundColor: spt.is_premium ? null : "#2b5283" }}>
                                              <img crossOrigin="anonymous" className={`icon-cam absolute`} src={require("../../assets/icons/cam_white.svg")} alt="cam-icon" />
                                            </div>
                                          )}{" "}
                                          <div id={"gtm-spots-select-" + spt.id} className="spotCardContent">
                                            {spt.name} {spt.position_camera ? utils.getCameraPosition(spt.position_camera) : ""}{" "}
                                            <RatingBox>
                                              <StarRating
                                                unit="half"
                                                size={15}
                                                count={5}
                                                initialRating={spt.forecast[currentTime].rating}
                                                edit={false}
                                                isReadOnly={true}
                                                color2="#dddddd"
                                                activeColor="#FABC41"
                                              />
                                              <br></br>
                                            </RatingBox>
                                          </div>
                                        </Link>
                                      );
                                    })}
                                </ul>
                              </div>
                              <div className="col-lg-8"></div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ textAlign: "center", color: "#aaaaaa", margin: "1rem 0" }}>{t("spots_no_results_orientation")}</p>
                    )
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )}
              
              {/* Form: Ask to add new Spot */}
              <ButtonAction href={ i18n.language && i18n.language === "pt" ? "https://forms.gle/jtoAJL3ACPsXZAZd6" : "https://forms.gle/7JSoLdhNwbVkbyAP9" } 
                target='_blank' style={{ width: "fit-content", color: "#fff", margin: "20px auto" }}>{t("spots_request_new_spot")}</ButtonAction>
              
            </div>
          </section>
          
          <AdManager adUnit="/23206544098/spot-list-footer"/>

          <FooterComponent />
        </Animate>
      )}
    </div>
  );
};

const ButtonAction = styled.a`
  background-color: #425781;
  height: 40px;
  min-width: 60px;
  border-radius: 5px;
  text-align: center;
  padding: 0px;
  display: flex;
  align-items: center;
  width: 100;
  padding: 14px;
  border: none;
  margin: 0px 5px;

  @media (min-width: 1000px) {
    width: 80px;
    padding: 20px 24px;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  surfcams: state.surfcams,
  lan: state.lan,
  country: state.country,
  currentTime: state.currentTime,
});

export default connect(mapStateToProps)(Spots);
