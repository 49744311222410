import instance from "./service";
import utils from "../utils/utils";
import constant from "../utils/constant";

class DiscountCodeService {
  async check(couponId, planId, country) {
    let result = await instance.post(
      "/discount/check",
      { coupon_id: couponId, plan_id: planId+"", user_country: country },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    return result;
  }
//Verificar si el path es discount en core
  async activate(passwordAffiliate, discountCodeName) {
    let result = await instance.post(
      "/giftcard/activate",
      { affiliate_password: passwordAffiliate, discount_code_id: discountCodeName  },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    return result;
  }

}

const discountCodeService = new DiscountCodeService();
export default discountCodeService;
