import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import constant from "../utils/constant";
import { Link } from "react-router-dom";
import payment from "../modules/payment";
import store from "../utils/store";
import paymentErrorReducer from "../reducers/paymentErrorReducer";
import plans from "../modules/plans";
import selectPlanReducer from "../reducers/selectPlanReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import utils from "../utils/utils";
import LoaderComponent from "./LoaderComponent";

const ToastPaymentError = (props) => {
  const {user, paymentStatus} = props;
  const [show, setShow] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(!paymentStatus) checkPaymentStatus();
  }, []);

  useEffect(() => {
    setShow(paymentStatus);
  }, [paymentStatus]);

  const checkPaymentStatus = async () => {
    if(user?.nextPlanId != 1){
      let status = await payment.getPaymentStatus(props.user.id);
      if(status){
        paymentErrorReducer.savePaymentStatus(status);
        utils.addScript(process.env.REACT_APP_DLOCAL_ENDPOINT);
      } 
    }
  } 

  const renewPaymentMethod = async () => {
    setIsLoading(true);

    const plansData = await plans.getPlans(user.country);

    const currentPlan = plansData[user.nextPlanId]; 
    delete plansData[user.nextPlanId];

    selectPlanReducer.selectPlan(
      currentPlan,
      null, 
      null,
      plansData,
    );

    history.push({
      pathname: "/premium/checkout",
      search: window.location.search,
    });

  }

  return (
    <>
      {show && (
        <ToastContainer
          style={{
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            marginBottom: props.marginBottom,
            backgroundColor: props.backgroundColor,
            marginTop: props.marginTop,
            fontSize: props.fontSize,
            color: props.fontColor ? props.fontColor : "#fff",
            borderRadius: "0px",
          }}>
          <span className="toastSpan"><Trans i18nKey={"toast_payment_error_title"} components={{ bold: <strong /> }}/></span>
          <Trans i18nKey={"toast_payment_error_description"} />
          <span className="toastSpan callToAction" onClick={() => renewPaymentMethod()}><Trans i18nKey="toast_payment_error_call_action" components={{ bold: <strong /> }} /></span>
        </ToastContainer>
      )}
      {
        loading && (
          <div style={{background: "#fff", width: "100%", height: "100vh", position: "fixed", top: 0, zIndex: 9999}}>
          <LoaderComponent/>
          </div>
        )
      }
    </>
  );
};

const ToastContainer = styled.p`
  text-align: center;
  padding: 10px !important;
  border-radius: 3px;
  font-family: "Poppins";
  .callToAction{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    margin-left: 0% !important;
    margin-right: 0% !important;
    .toastSpan{
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .spinner-border {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  a{
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
  }

`;

const mapStateToProps = (state) => ({
  user: state.user,
  paymentStatus: state.paymentStatus,
});

export default connect(mapStateToProps)(ToastPaymentError);
