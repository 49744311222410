import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const AddComponent = (props) => {
  const { img, link } = props;

  return (
    <>
      {img !== undefined && img !== null && (
        <div className="container-xl" style={{ padding: "0px", marginTop: "0px" }}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <BannerContainer className="rounded container-xl" style={{ backgroundImage: `url(${img})` }}></BannerContainer>
          </a>
        </div>
      )}
    </>
  );
};

const BannerContainer = styled.section`
  padding: 10px 40px;
  margin-top: 15px;
  margin-bottom: 0px;
  height: 90px;
  width: 970px; 
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;

  .fill-background {
    content: "";
    background: #203656;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.6;
    top: 0;
    position: absolute;
    width: 100%;
    border-radius: 5px;
  }

  p {
    float: right;
    color: #fff;
    font-size: 1.3em;
    position: absolute;
    right: 60px;
    z-index: 99;
    top: 30px;
    font-family: "Poppins", sans-serif;
  }
  a {
    position: absolute;
    right: 60px;
    top: 80px;
    color: #495057;
  }


  @media (max-width: 769px) {
    height: 100px !important;
    width: 320px !important;
    
    p {
      margin-left: 20px;
      top: 10px;
    }

    a {
      left: 20px;
      right: auto;
    }

  }

}
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(React.memo(AddComponent));
