import React, {useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router-dom';
import { Ad } from 'react-ad-manager';
import { connect } from 'react-redux';
import '../utils/styles/adPopUp.css'

const AdPopUp = (props) => {
  const {user, routeList, status} = props;
  const [showPopUp, setShowPopUp] = useState(false);
  const [countDownTrigger, setCountDownTrigger] = useState(0);
  const { t } = useTranslation();
  const isPathnameChange = useRef(false);
  const isReadyToShow = useRef(false);
  const isRenderizedRef = useRef(false);
  const timerRef = useRef(null);
  const countRef = useRef(0);
  const location = useLocation();
    
  useEffect(() => {
    if(isRenderizedRef.current) return;
    if(user)
      if(!user?.isPremium && isValidRoute()){
        setShowPopUp(true);
        startCountDow();
        isRenderizedRef.current = true;
      }
      else isReadyToShow.current = true;
  }, [user]); //busquemos optimizar este proceso

  const isValidRoute = () => {
    if(routeList && !routeList.some( url => matchPath(location.pathname, {path: url, exact: true}) )) return false;
    let res = true;
    const keywords = ['landing', 'premium', 'welcome', 'premium-argentina', 'access'];
    const routeKeyword = window.location.pathname.split('/')[1];

    keywords.forEach( word => {
      if(word === routeKeyword) res = false;
    })
    if(routeKeyword == '' && status !== 'logged') res = false;

    return res;
  }

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      if(isPathnameChange.current){
        setShowPopUp(true);
        startCountDow();
      }
      else{
        isReadyToShow.current = true;
      }; 
    }, 30000);
  }

  const closePopUp = () => {
    setShowPopUp(false);
    if(timerRef.current) clearTimeout(timerRef.current);
    isPathnameChange.current = false;
    isReadyToShow.current = false;
    startTimer();
  }

  const startCountDow = () => {
    countRef.current = 3;
    const count = setInterval(() => {
      if(countRef.current <= 0) clearInterval(count);
      
      setCountDownTrigger(countRef.current);
      countRef.current--;
    }, 1000);
  }

  useEffect(() => {
    if(!user?.isPremium && isValidRoute()){
      if(isReadyToShow.current && !showPopUp){
        setShowPopUp(true);
      }
      else{
        isPathnameChange.current = true;
      }
    }
  }, [location.pathname]);

  return (
    showPopUp &&
    <div className='ad-popup-container'>
      <div className='ad-popup'>
        <span className='ad-popup-ad-container'>
          <Ad 
            adUnit='/23206544098/full-popup'
            size={[320, 480]}
            name='add-full-popup'
            refreshTimer={30000} //30s
          />
          {/* Test box below: */}
          {/* <span style={{width: '320px', height: '480px', background: 'red', display: 'flex'}}></span>  */} 
        </span>
        <button className='ad-popup-button' onClick={closePopUp} disabled={countRef.current > 0} >{t('ad_popup_continue') + (countRef.current > 0 ?  (" "  + countRef.current) : "")}</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  routeList: state.routeList,
  status: state.status,
});

export default connect(mapStateToProps)(AdPopUp);
