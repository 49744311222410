import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import constant from "../utils/constant";
import { Spinner } from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import CancelSubComponent from "../components/cancelSubComponent";
import { AiFillQuestionCircle } from "react-icons/ai";
import payment from "../modules/payment";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import discountCodeService from "../modules/discountCodeService";

const DiscountCode = (props) => {
  const { t } = useTranslation();
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [error, setError] = useState("");
  const [amountToPay, setAmountToPay] = useState(0);

  useEffect(() => {
    if (props.referralcode && props.referralcode !== "") {
      checkDiscount(props.referralcode);
      document.getElementById("coupon").value = props.referralcode;
      setDiscount(true);
    }
  }, [])

  useEffect(() => {
    cancelCoupon();
  }, [props.userPlanId])

  const checkDiscount = async (cachedCoupon) => {
    setIsLoadingCoupon(true);
    setError("");
    let coupon = "";
    if (cachedCoupon !== "") {
      coupon = cachedCoupon;
    } else {
      coupon = document.getElementById("coupon").value;
    }
    coupon = coupon.trim();
    const response = await discountCodeService.check(coupon, props.userPlanId, props.userCountry);
    if (response.code === 200 && discount === false) {
      props.setDiscount(response.data);
      setDiscount(true);
    } else {
      if (response.code === 9000) setError(t("subscription_billing_coupon_not_exist"));
      else if (response.code === 9001) setError(t("subscription_billing_coupon_expired"));
      else if (response.code === 9002) setError(t("subscription_billing_coupon_limit_use"));
      else if (response.code === 9003) setError(t("subscription_billing_coupon_not_apply"));
      else if (response.code === 9004) setError(t("subscription_billing_coupon_not_apply_plan"));
      else if (response.code === 9005) setError(t("subscription_billing_coupon_not_active"));
      else if (response.code === 9006) setError(t("subscription_billing_coupon_not_apply_country"));
    }
    setIsLoadingCoupon(false);
  };

  const cancelCoupon = () => {
    document.getElementById("coupon").value = "";
    setError("")
    props.setDiscount(null);
    setDiscount(false);
  };

  return (
    <React.Fragment>
      {error !== "" &&
        <ErrorText>{error}</ErrorText>
      }
      <ContainerBox>
        <BillingInput type="text" placeholder={props.discount ? props.discount.name : t("subscription_billing_coupon")} id="coupon" className={error !== "" ? "error-input" : ""} />
        {discount === true && <AiOutlineClose className="cancel-coupon" onClick={() => cancelCoupon()} />}
        <BillingCouponButton onClick={() => checkDiscount("")} style={{ backgroundColor: discount === true ? "#5db24a" : "#004989" }} disabled={discount === true}>
          {isLoadingCoupon === true ? <Spinner /> : discount === true ? <AiOutlineCheck /> : t("subscription_billing_apply_coupon")}
        </BillingCouponButton>
      </ContainerBox>
    </React.Fragment>
  );
};



const ErrorText = styled.p`
 font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  padding-left: 0px;
  margin: 5px 0px;
  color: #db0000;
  text-align: center;

  @media (max-width: 1000px) {
    margin-left: -10px;
    text-align: center;
    }

`;

const ContainerBox = styled.div`
  display: inline-flex;
  padding-left: 0px;
  justify-content: center;
  .error-input{
    border: 1px solid #db0001;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #db0001 !important;
    } 
  }
  
  .cancel-coupon{
    float: right;
    margin-top: 25px;
    margin-left: -25px;
    margin-right: 15px;
    cursor: pointer;

    @media (max-width: 1000px) {
      position: absolute;
    }
  }

  @media (max-width: 1000px) {
    display: table;
    padding-right: 0px;
    padding: 0px 40px;
  }

`;

const BillingInput = styled.input`
  width: 30%;
  margin: 15px 0px;
  height: 40px;
  padding-left: 3px;

  @media (max-width: 1000px) {
      width: 100% !important;
      text-align: center;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  padding-left:  5px;
  font-weight: 600;
  font-size: 0.9em;

  @media (max-width: 1000px) {
      text-align: center;
      padding-left: 0px;
  }

}
`;

const BillingCouponButton = styled.button`
  border-radius: 5px;
  height: 45px;
  padding: 10px 20px;
  margin-top: 12px;
  margin-left: 20px;
  font-size: 0.9em;
  transition: 0.3s linear;
  color: #fff;
  border: none;

  .spinner-border {
    width: 1.3rem !important;
    height: 1.3rem !important;
  }

  :disabled,
  [disabled] {
    background-color: #d9dfe6;
    box-shadow: none;
    color: #fff;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    }



`;

export default DiscountCode;
