import React, {useRef, useEffect} from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Link } from 'react-router-dom';
import WaveQualityComponent from "./waveQualityComponent";
import constant from "../utils/constant";

const ChartComponentRating=(props)=>{
    const { chartData, premium, spotName} = props;
    const { t } = useTranslation();
    const starsContainerRef = useRef([]);

    useEffect(() => {
        starsContainerRef.current.forEach(htmlElem => {
            let linearGradientElem = htmlElem.querySelectorAll('linearGradient');
            linearGradientElem.forEach((elem) => {
                elem.setAttribute('x2', '0%');
                elem.setAttribute('y1', '100%');
            })
        })
    }, []);

    return(
    <>
        <ChartTitle>Valoración</ChartTitle>
        <ChartContainer premium={premium}>
            
            {!premium && 
                <PremiumAdd>
                        <h1>{t("Pronósticos exclusivos para Premium")}</h1>
                        <p>
                            <Trans
                                i18nKey="forecast_limited_modal_description" // optional -> fallbacks to defaults if not provided
                                values={{ spotName }}
                                components={{ italic: <i />, bold: <strong /> }}
                            />
                        </p>
                        <div className='premium-button-box'>
                            <Link to={{ pathname: "/premium", state: { prevPath: window.location.pathname, isRegister: true } }}>{t("forecaster_prono_premium_alert_button")}</Link>
                        </div>
                </PremiumAdd>
            }

            {chartData.map(({day,date,ratings},indexDay)=>{
                const needBlur = !premium && indexDay >= constant["FORECAST_DAYS_FREE_USER"];
                return( 
                <DayContainer key={crypto.randomUUID()} className={needBlur ? "blur" : ""}>
                    <DataContainer>
                        <Day content={day} mobileContent={day.slice(0, 2)}/>
                        <Date>{date}</Date> 
                        <RatingContainer ref={htmlElem => starsContainerRef.current[indexDay] = htmlElem } >
                            {ratings.map((rating, indexHour) => {
                                return <WaveQualityComponent key={crypto.randomUUID()} rating={needBlur ? (indexHour + 1) : rating} direction={"column-reverse"} />
                            })}
                        </RatingContainer>
                    </DataContainer>
                </DayContainer>)
            })}
            
        </ChartContainer>

    </>
    );
}

const DayContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid #e4e7ed;
`

const DataContainer = styled.div`
    width: 100%;
    height: auto;
    dispplay: flex;
    felx-direction: row;
    align-items: center;
`

const Day = styled.p`
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    margin: 0.5rem;

    &:before {
        content: "${props => props.content}";
    }

    @media(max-width: 1000px){
        &:before{
            content: "${props => props.mobileContent}";
        }
    }
`

const Date = styled.p`
    font-weight: 500;
    color: #82808080;
    text-align: center;
    margin: 0.5rem;
`
const RatingContainer = styled.div`
    display:flex;
    width: 100%;
    >div{
        width:33.3%;
        @media(max-width: 1000px){
            max-width: 20px;

            svg{
                width: 18px !important;
                height: 18px !important;
            }    
        }
    };
`
const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    > div:nth-child(${props => props.premium ? 1 : 2}){
        border: none;
    }
    position: relative;
`

const ChartTitle = styled.p`
    font-size: large;
    font-weight: bold;
    font-family: "Poppins",sans-serif;
    margin-right: 35px;
    position: sticky;
    left: 0;
`;

const PremiumAdd = styled.div`
    margin: 10px auto;
    padding: 15px 5px;
    border-radius: 5px;
    width: 70%;

    position: absolute;
    z-index: 9999;
    left: 33.3%;
    top: 50%;
    transform: translateY(-50%);

    @media(max-width: 1000px){
        left: 66.6%;
    }

    h1{
        text-align: center;
        font-size: 1.7em;
        font-weight: bold;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        color: #000;
    }

    p{
        font-size: 1.3em;
        text-align: center;
        width: 60%;
        margin: auto;
    }

    a{
        background-color: #32c943;
        border-radius: 5px;
        color: #fff;
        margin: auto;
        padding: 8px 25px !important;
        font-size: 0.9em;
    }

    div{
        margin: 10px auto 0 auto;
        width: fit-content;
    }


    @media(max-width: 999px){
        width: 100%;

        h1{
            font-size: 1.5em;
        }

        p{
            font-size: 1.1em !important;
            width: 100%;
        }
    }
`
export default React.memo(ChartComponentRating);


