import * as firebase from "firebase/app";
import { getAuth, signInWithRedirect, GoogleAuthProvider, getRedirectResult, linkWithCredential, FacebookAuthProvider, OAuthProvider, ProviderId, EmailAuthProvider } from "firebase/auth";
import store from "../utils/store";
import constant from "../utils/constant";
import instance from "./service";
import { v4 as uuidv4 } from "uuid";
import utils from "../utils/utils";
import i18n from "i18next";
import multiSpotReducer from "../reducers/multiSpotReducer";
import liveStreamingMetric from "./liveStreamingMetric";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
// import socket from "./socket";

const isAppleDeviceViaUrl = window.location.hostname.toLowerCase().split('.')[0] === 'ios';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: (isAppleDeviceViaUrl ? "ios." : "") + process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth();

class Auth {
  fpPromise = FingerprintJS.load();

  /**
   * Retrieves the authentication result after returning from the redirect when the app initializes.
   * @returns {Promise<Object>} The authentication result.
   */
  async getAuthResult() {
    // After returning from the redirect when your app initializes you can obtain the result
    const result = await getRedirectResult(firebaseAuth)

    /* 
    response 
    {
      operationType: "signIn"
      providerId: "google.com"
      user: {
        "uid": "cp3hB8NF2wMtI9vviwM0KlVNdAP2",
        "email": "matiasgumma@gmail.com",
        "emailVerified": true,
        "displayName": "Matias Gummá",
        "isAnonymous": false,
        "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ7feIOJF77TsMUtgsR-TKXbDbOg4AFCXUKo4gaLiKHJQ=s96-c",
        "providerData": [
            {
                "providerId": "google.com",
                "uid": "113499922108510821027",
                "displayName": "Matias Gummá",
                "email": "matiasgumma@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ7feIOJF77TsMUtgsR-TKXbDbOg4AFCXUKo4gaLiKHJQ=s96-c"
            },
            {
                "providerId": "password",
                "uid": "matiasgumma@gmail.com",
                "displayName": "Matias Gummá",
                "email": "matiasgumma@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ7feIOJF77TsMUtgsR-TKXbDbOg4AFCXUKo4gaLiKHJQ=s96-c"
            }
        ],
        "stsTokenManager": {
            "refreshToken": "AMf-vBy0Eua-ZLbGbd3Z-M-5nNkSbH8CY57tIdLpAKYH4RBgfaW8EvnwgKd79UdT0m1msjKr-0ZQQHiqycMjKIWtRghRLBgMZnr9l6W78vl6RklPYc5U9-WmwfoB4naHkh-NG8bnGqKO5zIbv_rtJoaSLqYQOqQnezvRSIbsR8Jp7WWS6iPFBOQHhBxQFmRjJQ5WivAnSW3fV3m8V6nXp_MxYmMh0NynXtir6VhdwuCa6WRIkisoWCJbE1GR6ypRNxgmpNL5apYLbnTEYM37rAD-mdbF8zpUS-CHjp0DDQj5l0D2ktolY-oxDlhZJDbgl_eHPkkGZ2zNCXCJgmVr6w61e3jj4iXwmyyDo2MmX31Uf_Bdy8Bblz5RynogDUl91EWl8NSozjbJ-gwHvIGuUY9yv0VghaqtVaceDo7Lyw3XN3naYs3PyoE",
            "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjAzZDA3YmJjM2Q3NWM2OTQyNzUxMGY2MTc0ZWIyZjE2NTQ3ZDRhN2QiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTWF0aWFzIEd1bW3DoSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NKN2ZlSU9KRjc3VHNNVXRnc1ItVEtYYkRiT2c0QUZDWFVLbzRnYUxpS0hKUT1zOTYtYyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9saW5ldXAtd2Vic2l0ZSIsImF1ZCI6ImxpbmV1cC13ZWJzaXRlIiwiYXV0aF90aW1lIjoxNjk3NjY4MjczLCJ1c2VyX2lkIjoiY3AzaEI4TkYyd010STl2dml3TTBLbFZOZEFQMiIsInN1YiI6ImNwM2hCOE5GMndNdEk5dnZpd00wS2xWTmRBUDIiLCJpYXQiOjE2OTc2NjgyNzMsImV4cCI6MTY5NzY3MTg3MywiZW1haWwiOiJtYXRpYXNndW1tYUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjExMzQ5OTkyMjEwODUxMDgyMTAyNyJdLCJlbWFpbCI6WyJtYXRpYXNndW1tYUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.UpBo4gpZGldXRBdSldUbcozHZK2Iu0mph6Db_5UCWL9-aiOAQGt2ugNI93UJJxWtqTlVr7J3SkHFsE_IA9jTg1Nitniw2oaD1FLcfXNDocpRQrtb-WnuqqHnTfcS0rS-aR0Rk7YYz_FFNyaFcsa4J8gNHAvyHvD3vY9Z92ffrXHZn-5u5yQXlLsI0-iP1XCd198rdn8Jf2WjSo7RcJK1LKA9GMFxP6KP-UFTLbqsnoBOjvI8NpA9il60LvtU1ftZbfiOPuMsQw8qAnmQ2wEFk7WAbriQfPDaMKgVeXpZ337jYqULIqyp9PBE6yOqCcdNjcO9zsy5hF-voGZB0UCkmg",
            "expirationTime": 1697671872877
        },
        "createdAt": "1689628054048",
        "lastLoginAt": "1697668273067",
        "apiKey": "AIzaSyAX-xbyMaStSin0o2t6Kq459As6HVj9h-w",
        "appName": "[DEFAULT]"
      }
      _tokenResponse: {
        context: ""
        displayName: "Matias Gummá"
        email: "matiasgumma@gmail.com"
        emailVerified: true
        expiresIn: "3600"
        federatedId: "https://accounts.google.com/113499922108510821027"
        firstName: "Matias"
        fullName: "Matias Gummá"
        idToken: "eyJhbGciOiJSUzI1N..."
        kind: "identitytoolkit#VerifyAssertionResponse"
        lastName: "Gummá"
        localId: "cp3hB8NF2wMtI9vviwM0KlVNdAP2"
        oauthAccessToken: "ya29.a0AfB_byATEzU0k..."
        oauthExpireIn: 3596
        oauthIdToken: "eyJhbGciOiJSUzI1NiI..."
        photoUrl: "https://lh3.googleusercontent.com/a/ACg8ocJ7feIOJF77TsMUtgsR-TKXbDbOg4AFCXUKo4gaLiKHJQ=s96-c"
        providerId: "google.com"
        rawUserInfo: "{\"name\":\"Matias Gummá\",\"granted_scopes\":\"https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile\",\"id\":\"113499922108510821027\",\"verified_email\":true,\"given_name\":\"Matias\",\"locale\":\"es\",\"family_name\":\"Gummá\",\"email\":\"matiasgumma@gmail.com\",\"picture\":\"https://lh3.googleusercontent.com/a/ACg8ocJ7feIOJF77TsMUtgsR-TKXbDbOg4AFCXUKo4gaLiKHJQ=s96-c\"}"
        refreshToken: "AMf-vBwvXassG-..."
      }
    }
    */
    return result ?? null
  }

  /**
   * This function handles the Google sign-in process.
   * It redirects the user to the Google sign-in page and waits for the authentication response.
   * Once the user is authenticated, it calls the signInWithRedirect function with the Firebase authentication and provider objects.
   */
  googleSignInHandler() {
    // Before starting sign in with redirect.
    const provider = new GoogleAuthProvider();
    signInWithRedirect(firebaseAuth, provider);
  }
  
  appleSignInHandler() {
    // Before starting sign in with redirect.
    const provider = new OAuthProvider('apple.com');
    signInWithRedirect(firebaseAuth, provider);
  }

  facebookSignInHandler() {
    // Before starting sign in with redirect.
    const provider = new FacebookAuthProvider();
    signInWithRedirect(firebaseAuth, provider);
  }

  async signUpOnNotFoundHandler(country) {
    const user = firebaseAuth.currentUser;
    // si ya se autentico...
    const token = await user.getIdToken();
    let logged = false;
    const resultResponse = await instance.post("/signUpWithSSO", { 
      email: user.email, 
      name: user.displayName, 
      language: i18n.language,
      country: country,
      userId: user.uid, 
      idToken: token, 
      refreshToken: user.refreshToken
    });

    // console.log("signUpWithSSO: ", resultResponse)

    if (resultResponse["error"]) {
      logged = {
        status: "error",
        error: resultResponse["error"],
      };
    } else {
      const cookieData = {
        [constant.COOKIE_AUTH]: resultResponse["token"],
        [constant.COOKIE_AUTH_REFRESH]: resultResponse["refreshToken"],
        [constant.COOKIE_USER]: resultResponse["id"],
      };
      this.deleteCookie(constant.COOKIE_AUTH);
      this.setCookie(constant.COOKIE_AUTH, JSON.stringify(cookieData));
      this.addUserAccess()      
      await this.authme(resultResponse);
      logged = {
        status: "ok",
      };
    }
    return logged;
  }
  
  async linkAccountHandler(provider) {
    let credential = null;
    if (provider == "apple.com"){
      credential = (new OAuthProvider('apple.com')).credential(firebaseAuth.currentUser.getIdToken());
    } else if (provider == "facebook.com"){
      credential = FacebookAuthProvider.credential(firebaseAuth.currentUser.getIdToken());
    } else {
      // Default Google
      credential = GoogleAuthProvider.credential(firebaseAuth.currentUser.getIdToken());
    }
    await linkWithCredential(firebaseAuth.currentUser, credential)
      .then((result) => {
        console.log("Account linking success");
      }).catch((error) => {
        console.log("Account linking error", error);
      });
  }
  
  /**
   * This function is used for signing in with SSO (Single Sign-On).
   * It takes an authResult object as a parameter, which contains the user's authentication information.
   * The function sends a POST request to the "/signInWithSSO" endpoint with the user's uid, accessToken, and refreshToken.
   * If the request is successful, it sets the necessary cookies, adds user access, and calls the authme function.
   * Finally, it returns an object indicating the status of the sign-in process.
   *
   * @param {Object} authResult - The authentication result object containing the user's authentication information.
   * @returns {Object} - An object indicating the status of the sign-in process.
   */
  async signInWithSSO(authResult) {
    let logged = false;
    const resultResponse = await instance.post("/signInWithSSO", { 
      email: authResult.user.email, 
      userId: authResult.user.uid, 
      idToken: authResult.user.accessToken, 
      refreshToken: authResult.user.stsTokenManager.refreshToken
    });

    if (resultResponse["error"]) {
      logged = {
        status: "error",
        error: resultResponse["error"],
      };
    } else {
      const cookieData = {
        [constant.COOKIE_AUTH]: resultResponse["token"],
        [constant.COOKIE_AUTH_REFRESH]: resultResponse["refreshToken"],
        [constant.COOKIE_USER]: resultResponse["id"],
      };
      this.deleteCookie(constant.COOKIE_AUTH);
      this.setCookie(constant.COOKIE_AUTH, JSON.stringify(cookieData));
      this.addUserAccess()      
      await this.authme(resultResponse);
      logged = {
        status: "ok",
      };
    }
    return logged;
  }

  async signIn(email, password) {
    let logged = false;
    const resultResponse = await instance.post("/signIn", { email: email, password: password });
    if (resultResponse["error"]) {
      logged = {
        status: "error",
        error: resultResponse["error"],
      };
    } else {
      const cookieData = {
        [constant.COOKIE_AUTH]: resultResponse["token"],
        [constant.COOKIE_AUTH_REFRESH]: resultResponse["refreshToken"],
        [constant.COOKIE_USER]: resultResponse["id"],
      };
      this.deleteCookie(constant.COOKIE_AUTH);
      this.setCookie(constant.COOKIE_AUTH, JSON.stringify(cookieData));
      this.addUserAccess()      
      await this.authme(resultResponse);
      logged = {
        status: "ok",
      };
    }
    return logged;
  }

  async signUp(email, password, name, phone, country) {
    let result = null;
    const resultResponse = await instance.post("/signUp", { email: email, password: password, name: name, phone: phone, language: i18n.language, country: country });
    if (resultResponse["error"]) {
      result = {
        status: "error",
        error: resultResponse["error"],
      };
    } else {
      const cookieData = {
        [constant.COOKIE_AUTH]: resultResponse["token"],
        [constant.COOKIE_AUTH_REFRESH]: resultResponse["refreshToken"],
        [constant.COOKIE_USER]: resultResponse["id"],
      };
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const queryParams = utils.parseCampaignParams(params);
      if (queryParams["utm_id"]) {
        liveStreamingMetric.postAdICampaingMetric({ ...queryParams, user_id: resultResponse["id"], action_type: constant.CAMPAIGN_TYPE.REGISTER });
      }
      this.deleteCookie(constant.COOKIE_AUTH);
      this.setCookie(constant.COOKIE_AUTH, JSON.stringify(cookieData));
      this.addUserAccess()
      this.authme(resultResponse);
      result = {
        status: "ok",
      };
    }
    return result;
  }

  async verifyEmail(code) {
    const resultResponse = await instance.post("/verifyEmail", { verifcation_code: code });
    const statusResult = resultResponse["code"];
    const currentState = store.getState();
    if (currentState["status"] === "logged") {
      await this.getUser();
    }
    return statusResult;
  }

  async deleteUser(userId){
    const result = await instance.post("/deleteAccount", { 
        user_id: userId, 
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH)
      });
    console.log(result)
    return result;
  }

  async getPremiumStatus(email, benefitId){
    const result = await instance.get("/getPremiumStatus", { params: {email: email, benefitId: benefitId} });
    return result;
  }

  async updateSocialInvite() {
    const resultResponse = await instance.post("/updateSocialInvite", {});
    await this.getUser();
  }

  async reSendVerificationEmail() {
    await instance.post("/resendConfirmEmail", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });
  }

  async updateLanguage(language) {
    let result = null;
    const resultResponse = await instance.post("/updateLanguage", { language: language });
    if (resultResponse && resultResponse["error"]) {
      result = {
        status: "error",
        error: resultResponse["error"],
      };
    } else {
      result = {
        status: "ok",
      };
    }
    return result;
  }

  async updateCountry() {
    const currentState = store.getState();
    const resultResponse = await instance.post("/updateCountry", { country: currentState["country"] });
  }

  async updateDocument(document) {
    await instance.post("/user/updateDocument", { document: document });
  }
  
  async updateUserName(name) {
    const { data, status, message } = await instance.post("/user/updateName", { name: name });

    console.info(message);

    if (status == "ok") {
      store.dispatch({
        type: "USER_NAME",
        data: data,
      });

      return true;
    }
    return false;
  }
  
  async uploadAvatar(file, old) {
    const formData = new FormData();
          formData.append('file', file)
          formData.append('old', old)
    const { data, status, message } = await instance.post("/user/uploadAvatar", formData, {headers: {'Content-Type': 'multipart/form-data'}})

    console.info(data);
    console.info(message);

    if (status == "ok") {
      store.dispatch({
        type: "AVATAR",
        data: data,
      });

      return true;
    }
    return false;
  }
  
  async removeAvatar(old) {
    const formData = new FormData();
          formData.append('old', old)
    const { data, status, message } = await instance.post("/user/removeAvatar", formData, {headers: {'Content-Type': 'multipart/form-data'}})

    console.info(data);
    console.info(message);

    if (status == "ok") {
      store.dispatch({
        type: "AVATAR",
        data: "",
      });

      return true;
    }
    return false;
  }

  async getUser() {
    const result = await instance.get("/getUser", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });
    if (result !== null) {
      await this.authme(result);
      if(result.country === null){
        try {
          this.updateCountry();
        } catch (error) {
          console.log("error country")
        }
      }
    } else {
      this.logout("", "")
    }
    return result;
  }

  async refreshToken() {
    const refreshToken = utils.getCookieValue(constant.COOKIE_AUTH_REFRESH);
    const result = await instance.post("/refreshToken", { refresh_token: refreshToken });
    if (result !== null) {
      const cookieData = {
        [constant.COOKIE_AUTH]: result["token"],
        [constant.COOKIE_AUTH_REFRESH]: refreshToken,
        [constant.COOKIE_USER]: utils.getCookieValue(constant.COOKIE_USER),
      };
      this.setCookie(constant.COOKIE_AUTH, JSON.stringify(cookieData));
    }
  }

  async sendEmailResetPswd(email) {
    const result = await instance.post("/sendEmailResetPswd", { email: email });
    if (result !== undefined && result["error"] === undefined) {
      return true;
    }
    return false;
  }

  async changePwsd(code, pswd) {
    const result = await instance.post("/changePassword", { code: code, pswd: pswd });
    if (result !== undefined && result["error"] === undefined) {
      return true;
    }
    return false;
  }

  setFavoriteSport(sport, otherText){
    const result = instance.post("/user/favoriteSports", { 
      favorite_sport: otherText,
      favorite_sport_type: sport,
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });
    if (result !== undefined && result["error"] === undefined) {
      return true;
    }
    return false;
  }

  addUserAccess(){
    this.fpPromise.then((fp) => fp.get()).then((result) => {
      // socket.instantiate(result.visitorId);
      instance.post("/user/access", { 
        fingerprint: result.visitorId,
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      });
      store.dispatch({
        type: "SET_FINGERPRINT",
        data: result.visitorId,
      });
    });
  
  }

  async setCookie(name, value) {
    document.cookie = name + "=" + value + ";max-age=2147483647;path=/;SameSite=None;Secure";
  }

  deleteCookie(name) {
    document.cookie = name + "=;Max-Age=0;path=/;SameSite=None;Secure";
  }

  async authme(data) {
    i18n.changeLanguage(data.language);
    // setTimeout(() => {
    //   console.log("IN YOUR FACE FIREBASE");
    store.dispatch({
      type: "LOGIN",
      data: data,
    });
    store.dispatch({
      type: "APPLE_USER",
      data: (utils.isAppleAccount(data.mail) && utils.isAppleDomain()),
    })
    // }, 5000);
    return true;
  }

  async logout(id, token) {
    this.setCookie(constant.COOKIE_AUTH);
   
    //console.log(firebaseAuth);
    if(firebaseAuth)
        firebaseAuth.signOut();
    
    multiSpotReducer.cleanSpots();
    store.dispatch({
      type: "LOGOUT",
      data: null,
    });
  }

  async createGuest() {
    const id = "guest-" + uuidv4();
    document.cookie =
      constant.COOKIE_AUTH +
      "=" +
      JSON.stringify({
        [constant.COOKIE_GUEST]: id,
      }) +
      ";max-age=588000;path=/;SameSite=None;Secure";

    this.accessGuest(id);
  }

  async accessGuest(id) {
    store.dispatch({
      type: "ACCESS_GUEST",
      data: id,
    });
    return true;
  }

  async getProRated() {
    const result = await instance.get("/user/getProratedAmount", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });
    if (result !== null) {
      return result
    } else {
      return null;
    }
  }

  isSSOSession(){
   // console.log(firebaseAuth);
   // return ((firebaseAuth)&& (firebaseAuth.currentUser)&&(firebaseAuth.currentUser.providerId != EmailAuthProvider.PROVIDER_ID));
    return ((firebaseAuth)&& (firebaseAuth.currentUser));
  }

}

const auth = new Auth();

export default auth;
