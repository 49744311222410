import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BenefitCard = (props) => {
  const {t} = useTranslation();
  const {title, img, id, logged} = props.benefit;

  return (
    <Link to={logged ? '/account/benefits' : '/access'} className="spot-card-items-container" style={styles.container}>
      <div className=" spot-card-items">
        <img src={img} alt={"benefit icon"} style={styles.image} />
        <div id={"gtm-list-spots-" + id} className="spotContent">
          <span>{title}</span>
          <p style={styles.viewMoreButton}>{t('_VIEW_MORE')}</p>
        </div>
      </div>
    </Link>
  );
};

const styles = {
  container: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  viewMoreButton: {
    fontWheight: '500',
    color: '#007bff',
    margin: '0',
  },
  image: {
    objectFit: 'contain',
  }
}

export default BenefitCard;
