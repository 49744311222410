import React, {useState, useEffect} from 'react';
import {BiLinkExternal} from 'react-icons/bi';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import constant from '../utils/constant';
import { Link } from 'react-router-dom';
import ReadMore from './ReadMore';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const ForecasterComponent = (props) => {
    const {forecast, user, lan, isAppleAccount} = props;
    const {name: forecasterName, src: forecasterImage, bio: forecasterBio} = constant["FORCASTERS"][forecast.country];
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('');
    const [freeDescription, setFreeDescription] = useState('');
    const [premiumDescription, setPremiumDescription] = useState('');
    const [pronoDay, setPronoDay] = useState();
    const [pronoMonth, setPronoMonth] = useState();
    const [pronoDate, setPronoDate] = useState();
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        let userLanguage = i18n.language ? i18n.language : lan; 
        i18n.language ? setLanguage(i18n.language) : setLanguage(lan);

        // Se Parsea lainformacion y se pasa a estados para que se puedan mostrar correctamente en la vista
        let created_date = new Date(forecast.created_date + 'T00:00'); // se concatena T00:00 para que de el dia correcto, por defecto da un dia antes
        setPronoDay(created_date.getDay());
        setPronoMonth(created_date.getMonth());
        setPronoDate(created_date.getDate());

        setPremiumDescription(forecast["premium_description_"+ userLanguage ]);
        setFreeDescription(forecast["free_description_"+ userLanguage ]);

        // Se calcula cuantos dias hay entre la fecha de actualizacion del prono y el dia actual, en el caso que pasaran mas de 7 dias o haya cambiado el mes no se muestra
        const today = new Date();
        const maxDate = new Date(created_date);
        maxDate.setDate( maxDate.getDate() + 7 )
        if((today >= created_date) && (today <= maxDate)){
            setUpdated(true);
        }
    },[forecast]);

  return (

    !updated ?
        <Skeleton height={328} style={{width: "100%"}}/>
    :
        <section className='forecaster-box' id="expert">
            <div className='forecaster-card'>
                <img className='forecaster-image' src={forecasterImage}></img>
                <div>
                    <span>
                        <a href={forecasterBio} target='_blank'>{forecasterName}</a>
                        <BiLinkExternal />
                    </span>
                    <h6><Trans
                        i18nKey={"forecaster_prono_update"}
                        values={{
                            day: constant["DAYS"][language][pronoDay],
                            day_date: pronoDate,
                            month: constant["MONTHS"][language][pronoMonth]
                        }}
                    ></Trans></h6>
                </div>
            </div>

            <article className='forecaster-prono'>
                <h1>{t("expert_forecast")}</h1>
                <ReadMore children={freeDescription} lines={3}/>
            </article>

            <br></br>

            {/* Premium Forecast */}
            { !isAppleAccount &&
                <>
                { user?.isPremium ?
                    <article className='forecaster-prono'>
                        <h1>{t("premium_expert_forecast")}</h1>
                        <ReadMore children={premiumDescription} lines={3}/>
                    </article>
                    :
                    <article className='forecaster-prono'>
                        <h1>{t("premium_expert_forecast")}</h1>
                        <div className='relative' style={{minHeight: "200px"}}>
                            <ReadMore children={freeDescription} lines={7} style={ !user?.isPremium ? {filter: "blur(2px)"} : {}}/>
                            <article className='premium-alert'>
                                <h1>{t("forecaster_prono_premium_alert_title")}</h1>
                                <p>{t("forecaster_prono_premium_alert_description")}</p>
                                <div className='premium-button-box'>
                                    <Link to={{ pathname: "/premium", state: { prevPath: window.location.pathname, isRegister: true } }}>{t("forecaster_prono_premium_alert_button")}</Link>
                                </div>
                            </article>
                        </div>
                    </article>
                }
                </>
            }

        </section>
  )
}

const mapStateToProps = (state) => ({
    user: state.user,
    lan: state.lan,
    isAppleAccount: state.isAppleAccount,
});

export default connect(mapStateToProps)(ForecasterComponent);