import React, { useState, useEffect } from "react";
import styled from "styled-components";
import payment from "../modules/payment";
import constant from "../utils/constant";
import { Spinner } from "reactstrap";
import utils from "../utils/utils";
import spotService from "../modules/spotService";
import { useTranslation, Trans } from "react-i18next";
import { Label, FormGroup, Input, Form } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";
import { Alert, Button, Space, Popconfirm } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import plans from "../modules/plans";
import getPlacements from "antd/es/_util/placements";
import paymentErrorReducer from "../reducers/paymentErrorReducer";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />;

const CancelSubComponent = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [needTextBox, setNeedTextBox] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [textBoxType, setTextBoxType] = useState("");
  const [cancelStep, setCancelStep] = useState(1);
  const [isMobile, setIsMobile] = useState(utils.isUsingMobile());
  const [userTime, setUserTime] = useState({});
  const [userSpots, setUserSpots] = useState();
  const [userFavorites, setUserFavorites] = useState();
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [amountPlan, setAmountPlan] = useState(0);
  const checkOption = (id) => {
    if (cancelReason != "") {
      setCancelReason("");
    }

    document.getElementById(id).checked = true;
    setCancelReason(id);
    setTextBoxType(id);
    setNeedTextBox(true);
  };

  useEffect(() => {
    getUserInfo();
    getPlanAmount()
  }, []);

  const getPlanAmount = async () => {
    let data = await plans.getPlan(3)
    setAmountPlan( data.amount )
  }

  const getUserInfo = async () => {
    const data = await spotService.getUserLastWatchtime();

    const dataKeys = Object.keys(data);

    if (dataKeys.length > 0) {
      //get number of the last watch user spots
      setUserSpots(data[dataKeys].total_spots);
      //get user last watch time
      setUserTime(utils.fancyTimeFormat(data[dataKeys].monthWatchtime, t("_HOURS"), t("_SECONDS"), t("_MINUTOS")));
    }

    //get number of the user favorites spots
    getFavoritesSpots();
  };

  const getFavoritesSpots = async () => {
    let favoritesSpotList = [];
    if (!props.user.favorites) {
      return;
    }
    favoritesSpotList = await spotService.getSpots(props.user.favorites, false);
    setUserFavorites(favoritesSpotList.length);
  };

  const cancelSubscription = async (e) => {
    let cancelComment = document.getElementById(`cancelReasonText-${cancelReason}`).value.trim();
    if (!utils.handleFields(cancelComment, 2)) {
      e.preventDefault();
      setError(t("subscription_cancel_error"));
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    await payment.cancelSubscription(constant.SUBSCRIPTION.FREE, cancelReason, cancelComment);
    paymentErrorReducer.savePaymentStatus(false);
    props.setIsSubCanceled(true);
    props.goBack();
  };

  const moveToAnuallyPlan = async () => {
    setIsUpgrading(true);
    await payment.monthToAnual();
    setTimeout(() => {
      setIsUpgrading(false);
    }, 500);
    history.push({
      pathname: "/premium/checkout/success",
      search: "renewal=true",
    });
  };

  return (
    <>
      {isLoading === true ? (
        <LoaderHelper>
          <Spinner />
        </LoaderHelper>
      ) : (
        <React.Fragment>
          
          <FLexContainer>
            <CancelContainer>
              <Box>
                <object data={require("../assets/icons/borderWaves.svg")} />

                <div className="logo-container">
                  <img src={require("../assets/logo/white-logo.svg")} />
                </div>
                <div className="content-container">
                  <p>{t("subscription_cancel_title")}</p>

                  <h1>
                    <Trans i18nKey={"subscription_cancel_summary_title"} />
                  </h1>

                  <div className="content">
                    <span className="item">
                      <img src={require("../assets/icons/cam_white.svg")} />

                      <p>
                        <Trans
                          i18nKey={"subscription_cancel_summary_cameras"}
                          values={{
                            userSpots: userSpots,
                          }}
                        />
                      </p>
                    </span>

                    <span className="item">
                      <img src={require("../assets/icons/multispot.svg")} />
                      <p>
                        <Trans
                          i18nKey={"subscription_cancel_summary_favorites"}
                          values={{
                            userFavorites: userFavorites,
                          }}
                        />
                      </p>
                    </span>
                  </div>

                  <h1 style={{ marginBottom: "32px" }}>{t("subscription_cancel_feature_title")}</h1>

                  <span className="item">
                    <img className="small-icon" src={require("../assets/icons/close.svg")} />
                    <p>{t("subscription_cancel_feature_cam_rewind")}</p>
                  </span>

                  <span className="item">
                    <img className="small-icon" src={require("../assets/icons/close.svg")} />
                    <p>{t("subscription_cancel_feature_publicity")}</p>
                  </span>

                  <span className="item">
                    <img className="small-icon" src={require("../assets/icons/close.svg")} />
                    <p>{t("subscription_cancel_feature_premium_cams")}</p>
                  </span>

                  <span className="item">
                    <img className="small-icon" src={require("../assets/icons/close.svg")} />
                    <p>{t("subscription_cancel_feature_more")}</p>
                  </span>
                </div>
                <ContinueBannerButton onClick={() => props.goBack(false)} >
                  {t("subscription_cancel_button_continue")}
                </ContinueBannerButton>{" "}
              </Box>
            </CancelContainer>

            <div className="column-div">
              <div className="form-container">
                <PopUpTitle>{t("subscription_cancel_description")}</PopUpTitle>
                <ErrorText className="error-text">{error}</ErrorText>
                <CancelForm>
                  <Form>
                    <FormGroup check onClick={() => checkOption("willUseAnotherProduct")}>
                      <Input name="cancelSub" id="willUseAnotherProduct" type="radio" /> <Label check>{t("subscription_cancel_reason_4")}</Label>
                    </FormGroup>
                    <Fade top opposite collapse delay={300} when={needTextBox === true && textBoxType === "willUseAnotherProduct"}>
                      <FormGroup>
                        <Label check>{t("subscription_cancel_reason_4_text")}</Label>
                        <CancelComment id="cancelReasonText-willUseAnotherProduct" type="textArea" />
                      </FormGroup>
                    </Fade>
                    <FormGroup check onClick={() => checkOption("notPracticingEnough")}>
                      <Input name="cancelSub" id="notPracticingEnough" type="radio" /> <Label check>{t("subscription_cancel_reason_6")}</Label>
                    </FormGroup>
                    <Fade top opposite collapse delay={300} when={needTextBox === true && textBoxType === "notPracticingEnough"}>
                      <FormGroup>
                        <Label check>{t("subscription_cancel_reason_6_text")}</Label>
                        <CancelComment id="cancelReasonText-notPracticingEnough" type="textArea" />
                      </FormGroup>
                    </Fade>
                    <FormGroup check onClick={() => checkOption("missingFeature")}>
                      <Input name="cancelSub" id="missingFeature" type="radio" /> <Label check>{t("subscription_cancel_reason_2")}</Label>
                    </FormGroup>
                    <Fade top opposite collapse delay={300} when={needTextBox === true && textBoxType === "missingFeature"}>
                      <FormGroup>
                        <Label check>{t("subscription_cancel_reason_2_text")}</Label>
                        <CancelComment id="cancelReasonText-missingFeature" type="textArea" />
                      </FormGroup>
                    </Fade>
                    <FormGroup check onClick={() => checkOption("serviceNotWorkingProperly")}>
                      <Input name="cancelSub" id="serviceNotWorkingProperly" type="radio" /> <Label check>{t("subscription_cancel_reason_3")}</Label>
                    </FormGroup>
                    <Fade top opposite collapse delay={300} when={needTextBox === true && textBoxType === "serviceNotWorkingProperly"}>
                      <FormGroup>
                        <Label check>{t("subscription_cancel_reason_3_text")}</Label>
                        <CancelComment id="cancelReasonText-serviceNotWorkingProperly" type="textArea" />
                      </FormGroup>
                    </Fade>
                    <FormGroup check onClick={() => checkOption("priceIsTooHigh")}>
                      <Input name="cancelSub" id="priceIsTooHigh" type="radio" /> <Label check>{t("subscription_cancel_reason_1")}</Label>
                    </FormGroup>
                    <Fade top opposite collapse delay={300} when={needTextBox === true && textBoxType === "priceIsTooHigh"}>
                      <FormGroup>
                        <Label check>{t("subscription_cancel_reason_1_text")}</Label>
                        <CancelComment id="cancelReasonText-priceIsTooHigh" type="textArea" />
                      </FormGroup>
                    </Fade>
                    <FormGroup check onClick={() => checkOption("otherReason")}>
                      <Input name="cancelSub" id="otherReason" type="radio" /> <Label check>{t("subscription_cancel_reason_5")}</Label>
                    </FormGroup>
                    <Fade top opposite collapse delay={300} when={needTextBox === true && textBoxType === "otherReason"}>
                      <FormGroup>
                        <Label check>{t("subscription_cancel_reason_5_text")}</Label>
                        <CancelComment id="cancelReasonText-otherReason" type="textArea" />
                      </FormGroup>
                    </Fade>
                    <CancelButton disabled={cancelReason === ""} onClick={(e) => cancelSubscription(e)} id="account-cancel-subscription-button">
                      {t("subscription_cancel_button")}
                    </CancelButton>{" "}
                    <ContinueButton onClick={() => props.goBack(false)} id="account-cancel-subscription-continue-button">
                      {" "}
                      {t("subscription_cancel_button_continue")}
                    </ContinueButton>
                  </Form>
                </CancelForm>
              </div>
            </div>
          </FLexContainer>
        </React.Fragment>
      )}
    </>
  );
};

const MessageInfo = styled.div`
  .ant-alert-message {
    font-weight: 800;
    font-size: 19px !important;
  }
  .ant-alert-action {
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 50px;
  }
`;

const FLexContainer = styled.div`
  display: flex;

  .column-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 60px 0 0 0;
    width: 500px;
    background: #ffffff;
    border-left: 2px solid rgba(0, 0, 0, 0.25);

    .form-container {
      width: 400px;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    .column-div {
      width: 100%;
      border: none;
      margin: 0;

      .form-container {
        width: 90%;
      }
    }
  }
`;

const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-4px 5px 4px rgba(0, 0, 0, 0.25));
`;

const Box = styled.div`
  width: 550px;
  max-height: 1000px;
  background: #425781;
  position: relative;
  margin: 60px 50px 0 0;

  @media (max-width: 1000px) {
    max-width: 550px;
    width: 100%;
    max-height: 1300px;
    margin: 60px 0;
    padding-top: 30px;
  }

  object {
    width: 550px;
    filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25));
    position: absolute;
    top: -55px;

    @media (max-width: 1000px) {
      width: 100%;
      top: -30px;
    }
  }

  .logo-container {
    width: 420px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;

    @media (max-width: 1000px) {
      display: none;
    }

    img {
      width: 140px;
      height: auto;
      margin: 30px 0;
    }
  }

  .content-container {
    width: 420px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media (max-width: 1000px) {
      width: 80%;
    }
  }

  .content {
    border-width: 2px 0px;
    border-style: solid;
    border-color: #ffffff;
    padding-top: 32px;
    margin-bottom: 32px;
    width: 100%;

    // @media(max-width: 1000px){
    //   width: 270px;
    // }
  }

  .item {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 32px;
      height: auto;
      margin-right: 32px;
    }

    p {
      margin: 0;
    }
  }

  p {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    // margin: 0 auto;
  }

  h1 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
  }

  label {
    color: #fff;
  }

  input[type="textarea"] {
    width: 100%;
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 19px;
    }

    p {
      font-size: 15px;
    }

    .item .small-icon {
      width: 20px;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px auto;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: fit-content;
    margin: 0 auto 30px auto;

    button {
      margin: 10px;
    }
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 150px;
    height: 47px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }

  .cancel-button {
    background: #fff;
    color: rgba(66, 87, 129, 1);
  }

  .premium-button {
    background: #5db24a;
    color: #fff;
  }
`;

const LoaderHelper = styled.div`
  height: 200px;
  text-align: center;
  padding-top: 70px;

  .spinner-border {
    color: #004989 !important;
    height: 45px !important;
    width: 45px !important;
  }
`;

const ErrorText = styled.p`
  text-align: left !important;
  margin-top: 20px;
`;

const CancelForm = styled.div`
  // margin-left: 20px;
  padding-top: 10px;
  border-top: 2px solid #425781;
  label {
    margin-left: 5px;
    margin-bottom: 5px;
    font-family: Poppins;
  }

  label {
    font-size: 18px;
  }

  .form-group label {
    font-size: 16px;
  }

  .form-check {
    padding-top: 10px;
  }
`;

const CancelButton = styled.button`
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  background-color: #004989;
  color: #fff;
  font-weight: 500;
  font-family: Poppins;
  margin-top: 10px;
  margin: 0 auto;
  display: flex;
  margin-top: 29px;
  margin-bottom: 15px;
`;

const ContinueBannerButton = styled.button`
background: #fff;
color: #425781;
margin: 0 auto;
margin-top: 15px;
margin-bottom: 29px;
padding: 13px 30px;
font-weight: 600;
border-radius: 5px; 
border: none;
display: flex;
`
  

const CancelComment = styled.input`
  margin-top: 10px;
  padding-left: 0px !important;
  width: 100%;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  font-family: Poppins;

  @media (max-width: 1000px) {
    width: 95%;
  }
`;

const ContinueButton = styled.button`
  border: none;
  background-color: transparent;
  color: #004989;
  font-weight: 500;
  font-family: Poppins;
  display: flex;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding-left: 0px;
    font-size: 0.9em;
  }
`;

const PopUpTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 500;
  padding-top: 20px;
  font-family: Poppins;

  @media (max-width: 1000px) {
    font-size: 19px;
  }
`;

const PopUpDescription = styled.p`
  font-weight: 400;
  font-family: Poppins;
`;

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CancelSubComponent);
