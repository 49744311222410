import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BiLike } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import spotService from '../modules/spotService';
import { Spinner } from 'reactstrap';
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const LikeCounter = (props) => {
  const { isAdding, spot, setWasRequested, status } = props;
  const [show, setShow] = useState();
  const history = useHistory();
  const [numberRequest, setNumberRequest] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let data = await spotService.getCameraRequest(spot.id);
      let { requests, user_rated } = data;
      if(status === 'logged'){ setWasRequested(user_rated); }
      setNumberRequest(requests);
      setIsLoading(false);
    })()

  }, [])

  useEffect(() => {
    setShow(isAdding);
    setNumberRequest(numberRequest + 1);
  }, [isAdding]);

    return (
      <>
        <Counter>
          <>
          {isLoading === true ?
            <SpinnerContainer>
              <Spinner></Spinner>
            </SpinnerContainer>
            :
            `+${numberRequest}`
          }
          </>
          <PopUp className={show === true && "request-camera-popup"}>+1</PopUp>
        </Counter>
      </>
    )
}

const RequestCameraButton = (props) => {
  const { spot, status } = props;
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [wasRequested, setWasRequested] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isLogged = (status === 'logged')

  useEffect(() => {
    (async () => {
      let data = await spotService.getCameraRequest(spot.id);
      let { user_rated = false } = data;
      setWasRequested(user_rated);
      setIsLoading(false);
    })()

  }, [])
  
  const sendRequest = () => {
    if(!isLogged){
      history.push({ 
        pathname: '/premium',
        search: window.location.search,
      });
      return;
    }
    setIsAdding(true);
    spotService.sendCameraRequest(spot.id);
    setWasRequested(true);
  }

  return (
    <RequestCameraContainer> 
      {/* <LikeCounter 
        isAdding={isAdding} 
        spot={spot}
        setWasRequested={setWasRequested}
        status={status}
      />  */}
      <button disabled={ wasRequested ? true : false  } className="request-camera" onClick={() => sendRequest()}>
        <BiLike className={isAdding && "hand-like"}/>
        {isLoading ? 
          <SpinnerContainer 
          style={{width: '40px', height: '40px'}}
          >
            <Spinner/>
          </SpinnerContainer>
          : 
          t((wasRequested && isLogged)? "request_spot_button_rated" : "request_spot_button") }
      </button>
    </RequestCameraContainer>
  )
}

LikeCounter.propTypes = {
  spot: PropTypes.object.isRequired,
  isAdding: PropTypes.bool.isRequired,
  setWasRequested: PropTypes.func.isRequired,
}

RequestCameraButton.propTypes = {
  spot: PropTypes.object.isRequired,
  status: PropTypes.string,
}

const RequestCameraContainer = styled.div`

width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;

.request-camera {
  background: #fff;
  border: 1px solid #55555552;
  outline: none;
  margin: 15px 0;
  height: 45px;
  border-radius: 5px;
  padding: 8px;
  background-color: rgb(66, 87, 129);
  color: #fff;

  display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 240px;

  

  svg {
    width: 20px;
    height: 20px;
  }

  .hand-like{
    animation: 0.5s 1 like;
  }

  @keyframes like{
    0%{
      transform: rotate(0deg);
    }

    50%{
      transform: rotate(45deg);
    }

    100%{
      transform: rotate(0deg);
    }
  }
}
button[disabled]{
  // background-color: #f8f8f8;
  background-color: rgba(66, 87, 129, 0.6);
  border: none;

}

`

const Counter = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  position: relative;
  border: 1px solid #55555552;

  .pop-up{
    background-color: rgb(66, 87, 129);
    color: #fff;
  }

  .request-camera-popup{
    animation: 1s 1 addLike;
  }

  @keyframes addLike{
    0%{ 
      width: 30px;
      height: 30px;
      visibility: visible;
      background: transparent;
      color: none;
    }

    30%{
      width: 50px;
      height: 50px;
      background: rgb(66, 87, 129);
      color: #fff;
    }

    50%{
      background: rgb(66, 87, 129);
      color: #fff;
      transform: translateY(0px)
    }

    99%{
      visibility: visible;
    }

    100%{
      visibility: hidden;
      background: transparent;
      color: none;
      transform: translateY(-100px)
    }

  }
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-border{
    width: 50%;
    height: 50%;
    color: #425781;
  }
`

const PopUp = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 8px;
  position: absolute;
 
  visibility: hidden;
  background: transparent;
  color: #fff;
`

export default RequestCameraButton