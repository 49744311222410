import store from "../utils/store";

class SelectPlanReducer {

  selectPlan(plan, coupon, userReferrer, otherPlans) {
    store.dispatch({
      type: "SELECT_PLAN",
      data: { 
        plan: plan, 
        coupon: coupon, 
        userReferrer: userReferrer,
        otherPlans: otherPlans
      }
    });
  }
}

const selectPlanReducer = new SelectPlanReducer();

export default selectPlanReducer;