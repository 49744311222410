import instance from "./spotGenericService";
import utils from "../utils/utils";
import constant from "../utils/constant";
import i18n from "../i18n/i18n";
import store from "../utils/store";
import currentTimeReducer from "../reducers/currentTimeReducer";

class SpotService {
  async getUserWatchtime() {
    let result = await instance.get("/spot/userWatchtime", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });
    result = this.formatWatchTime(result);
    return result;
  }

  async getForecasterPronos(){
    let result = await instance.get("/spot/getForecasterPronos", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });

    return result;
  }

  async updateForecasterProno(prono){
    try {
      let result = await instance.post(
        "/spot/updateExpertProno",
        {forecast: prono},
        {Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH)}
      );
      return result;
    }
    catch(err){
      return false;
    }
  }

  async getCameraRequest(spotId) {
    let result = await instance.get(`/spot/requestCamera?spot_id=${spotId}`);
    return result;
  }

  sendCameraRequest(spotId){
    instance.post(`/spot/requestCamera`,{spot_id: spotId}, {Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH)})
  }

  async getUserLastWatchtime() {
    let result = await instance.get("/spot/userLastWatchtime", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    });
    result = this.formatWatchTime(result);
    return result;
  }

  async getSpots(spots, all, needDuplicates = true, needForecast = false) {
    await currentTimeReducer.updateCurrentTime()
    try {
      let result = await instance.get(`/spot?spots=${spots}&duplicates=${needDuplicates}&forecast=${needForecast}`);
      return all === true ? utils.orderSpots(result) : result;
    } catch (error) {
      return null;
    }
  }

  async getSpotByUrl(urls) {
    try {
      let result = await instance.get("/spot/byUrl", { params: { url: urls } });
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSpotByName(name) {
    try {
      let result = await instance.get(`/spot/byName?name=${name}`);
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSpotByIds(id) {
    try {
      let result = await instance.get(`/spot/byId?id=${id}`);
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSpotUnique(id) {
    try {
      let result = await instance.get(`/spot/uniqueId?id=${id}`);
      return result;
    } catch (error) {
      return null;
    }
  }

  async getFreeCam() {
    try {
      const storeData = store.getState();
      let result = await instance.get(`/spot/getFreeCam?country=${storeData.country}`);
      return result;
    } catch (error) {
      return null;
    }
  }

  async getWelcomeCam(){
    try {
      let res = []; 
      const storeData = store.getState();
      res = await instance.get(`/spot/getWelcomeCam?country=${storeData.country}`);

      if(res.length > 0)
        return res[0];

      else
        return null;
      
    }catch(error){
      return null;
    }
  }

  async getSpotRewinds(id) {
    try {
      let result = await instance.get(`/spot/camrewind?id=${id}`, {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      });
      return result && result.length > 0 ? await this.sortRewinds(result) : [];
    } catch (error) {
      return [];
    }
  }

  async requestSpot(spotId) {
    instance.post(`/spot/requestCamera`,{ spot_id: spotId }, {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
    }
    );
}

  formatWatchTime(times) {
    const language = i18n.language;
    let timeList = {};

    for (let index = 0; index < times.length; index++) {
      const element = times[index];
      const userWatchtime = element["watchtime"];

      let watchtimeFormatted = new Date(element["watchtime_date"]);
      watchtimeFormatted.setDate(watchtimeFormatted.getDate() + 1);
      const dateMonth = watchtimeFormatted.getFullYear() + "-" + (watchtimeFormatted.getMonth() + 1);
      if (timeList[dateMonth] === undefined) {
        timeList[dateMonth] = {};
        timeList[dateMonth]["monthWatchtime"] = 0;
        timeList[dateMonth]["spots"] = 0;
        timeList[dateMonth]["values"] = {};
      }

      watchtimeFormatted.setHours(element["watchtime_hour"] + -3)

      if (timeList[dateMonth]["values"][element["spot_id"]] === undefined) {
        timeList[dateMonth]["values"][element["spot_id"]] = {};
        timeList[dateMonth]["values"][element["spot_id"]]["totalWatched"] = 0;
        timeList[dateMonth]["values"][element["spot_id"]]["values"] = {};
        timeList[dateMonth]["values"][element["spot_id"]]["name"] = element["name"];
        timeList[dateMonth]["values"][element["spot_id"]]["url"] = element["url"];
        timeList[dateMonth]["spots"]++;
      }
      timeList[dateMonth]["monthWatchtime"] += userWatchtime;
      timeList[dateMonth]["values"][element["spot_id"]]["totalWatched"] += userWatchtime;

      const day = `${watchtimeFormatted.toLocaleDateString(language + "-UY", { weekday: "long" })} ${watchtimeFormatted.getDate()}`;

      if (timeList[dateMonth]["values"][element["spot_id"]]["values"][day] === undefined) {
        timeList[dateMonth]["values"][element["spot_id"]]["values"][day] = {};
        timeList[dateMonth]["values"][element["spot_id"]]["values"][day]["values"] = {};
        timeList[dateMonth]["values"][element["spot_id"]]["values"][day]["totalWatched"] = 0;
      }

      let watchtimeHour = `${ watchtimeFormatted.getHours() }`;
      

      if (timeList[dateMonth]["values"][element["spot_id"]]["values"][day]["values"][watchtimeHour] === undefined) {
        timeList[dateMonth]["values"][element["spot_id"]]["values"][day]["totalWatched"] += userWatchtime;
        timeList[dateMonth]["values"][element["spot_id"]]["values"][day]["values"][watchtimeHour] = userWatchtime;
      }
    }
    
    return timeList;
  }

  async sortRewinds(data) {
    let result = {};
    let auxMapIndex = {};
    let auxIndex = 0;

    for (let index = 0; index < data.length; index++) {
      const rew = data[index];
      if (result[rew["day"]] === undefined) {
        result[rew["day"]] = [];
        auxMapIndex[rew["day"]] = {};
        auxIndex = 0;
      }
      if (auxMapIndex[rew["day"]][rew["hour"]] === undefined) {
        result[rew["day"]].push({
          values: [],
          hour: rew["hour"],
        });
        auxMapIndex[rew["day"]][rew["hour"]] = auxIndex;
        auxIndex = auxIndex + 1;
      }
      result[rew["day"]][auxMapIndex[rew["day"]][rew["hour"]]]["values"].push({
        hour: rew["fullHour"],
        url: rew["url"],
        day: rew["day"],
      });
    }
    for (var m in result) {
      result[m] = result[m];
      for (let index = 0; index < result[m].length; index++) {
        const hour = result[m][index];
        result[m][index]["values"] = hour["values"];
      }
    }
    return result;
  }

}

const spotService = new SpotService();

export default spotService;
