import { Alert } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Animate from "react-smooth/lib/Animate";
import styled from "styled-components";
import DiscountCode from "../components/discountCode";
import LoaderComponent from "../components/LoaderComponent";
import PremiumCardComponent from "../components/premiumCardComponent";
import plans from "../modules/plans";
import userService from "../modules/userService";
import selectPlanReducer from "../reducers/selectPlanReducer";
import constant from "../utils/constant";
import utils from "../utils/utils";
import spotService from "../modules/spotService";
import store from "../utils/store";

const PremiumLandingView = (props) => {
  const { user, isAppleDevice, country, spotList } = props;
  let history = useHistory();
  const { t } = useTranslation();
  const [plansList, setPlansList] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [referralcode, setReferralCode] = useState("");
  const [userReferrer, setUserReferrer] = useState(null);
  const [isMonthPlan, setIsMonthPlan] = useState(false);
  const [dlocal, setDlocal] = useState(false);
  const [showCupon, setShowCupon] = useState(false);
  const [camCount, setCamCount] = useState({premium: 0, free: 0});
  // constant plan id 
  const plan = constant.SUBSCRIPTION;

  useEffect(() => {
    let country = user.country ?? utils.getCustomCookie(constant.COOKIE_COUNTRY);
    getPlans(country);
    if (referralcode === "") {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const couponParam = params.get("coupon_id");
      if (couponParam !== null) {
        setReferralCode(couponParam);
        setShowCupon(true);
      }
    }

    if ((user?.referrer_id === null || !user.referrer_id) && userReferrer === null) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const publicId = params.get("ref_id");
      if (publicId !== null && (!user.public_id || user.public_id !== publicId)) {
        getUserReferrer(publicId);
      }
    }
    getSpotCount()
  }, [plansList]);

  const getSpotCount = async () => {
    let spotList = spotList;
    if(!spotList) spotList = await spotService.getSpots("", true);

    store.dispatch({
      type: "SPOT_LIST",
      data: spotList,
    })

    let count = {premium: 1, free: 0};
    Object.keys(spotList).forEach( country => {
      spotList[country].recommended.forEach( spot => {
        if(!spot.is_premium) count.free++;

        count.premium++;
      });
    });

    setCamCount(count);
  }

  useEffect(() => {
    if (!dlocal) {
      utils.addScript(process.env.REACT_APP_DLOCAL_ENDPOINT);
    }
    setDlocal(true);
  }, [plansList]);

  const getPlans = async (country) => {
    if (!plansList) {
      const plansData = await plans.getPlans(country);
      setPlansList(plansData);
    }
    setIsLoading(false);
  };

  const getUserReferrer = async (publicId) => {
    const userReferrerResponse = await userService.getPublicUser(publicId);
    if (userReferrerResponse != null) {
      setUserReferrer(userReferrerResponse);
    }
  };

  const selectedPlan = (plan) => {

    const otherPlans = { ...plansList };
    delete otherPlans[plan.id];

    // El reducer lleva la informacion al checkout
    selectPlanReducer.selectPlan(plan, discount, userReferrer, otherPlans);

    if (user && user.currentPlanId) {
      if (user.currentPlanId !== constant.SUBSCRIPTION.FREE && plan.id === constant.SUBSCRIPTION.FREE) {
        history.push({
          pathname: "/account/cancel-subscription",
        });
      } else {
        history.push({
          pathname: "/premium/checkout",
          search: window.location.search,
        });
      }
    } else {
      history.push({
        pathname: "/access",
        search: window.location.search,
      });
    }
  };

  return (
    <React.Fragment>
      {isLoading === true ? (
        <LoaderComponent />
      ) : (
        <div style={{ overflowX: "hidden" }}>

          <Helmet>  
            <title>{t("meta_title_views_premium_landing")} - {t("seo_title_page")}</title>
            <meta name="description" content={t("meta_description_views_premium_landing")} />
            <meta name="og:description" content={t("meta_description_views_premium_landing")} />
          </Helmet>

          <Animate to="1" from="0" duration={500} attributeName="opacity">
            <Header>
              <Link to={"/"}>
                <img src={require("../assets/logo/logo.svg")} alt={""} />
              </Link>
            </Header>
            <ViewTitle>{t("premium_landing_view_plan_title")}</ViewTitle>
            <ViewSubTitle>{t("premium_landing_view_plan_sub_title")}</ViewSubTitle>
            <div className="container-xl">
              <ContainerPlans>
                <>
                  {!!userReferrer && (
                    <div className="row">
                      <Alert
                        style={{ width: "500px" }}
                        message={
                          <>
                            <ViewUserReferralInformation>
                              {userReferrer.name} {t("premium_landing_view_user_referral_information")}
                            </ViewUserReferralInformation>
                          </>
                        }
                        description=""
                        type="info"
                      />
                    </div>
                  )}
                </>
                <div className="row">
                  {/* <PremiumDayCardComponent isPremiumPlan={false} monthlyPlan={plansList[plan.ESPECIAL_DAY]} discount={discount} selectedPlan={selectedPlan} especialdayIndex={1} />                   */}
                  {/* <PremiumDayCardComponent isPremiumPlan={false} monthlyPlan={plansList[plan.ESPECIAL_DAY]} discount={discount} selectedPlan={selectedPlan} especialdayIndex={2} /> */}
                  <PremiumCardComponent isPremiumPlan={false} freePlan={plansList[plan.FREE]} discount={discount} selectedPlan={selectedPlan} setIsMonthPlan={setIsMonthPlan} camCount={camCount}/>
                  <PremiumCardComponent isPremiumPlan={true} monthlyPlan={plansList[plan.MONTHLY]} anualPlan={plansList[plan.ANNUALLY]} discount={discount} selectedPlan={selectedPlan} setIsMonthPlan={setIsMonthPlan} camCount={camCount}/>
                </div>
              </ContainerPlans>

              {!isAppleDevice && (
                <ContainerCoupon>
                  <div className="row">
                    <ContCuponTitle>
                      <CuponTitle onClick={() => setShowCupon(!showCupon)}>{t("premium_landing_view_plan_discount_title")}</CuponTitle>
                      {!showCupon ? <AiOutlineArrowDown onClick={() => setShowCupon(!showCupon)}></AiOutlineArrowDown> : <AiOutlineArrowUp onClick={() => setShowCupon(!showCupon)}></AiOutlineArrowUp>}
                    </ContCuponTitle>
                    {showCupon && <DiscountCode setDiscount={setDiscount} discount={discount} referralcode={referralcode} userPlanId={isMonthPlan ? 2 : 3} userCountry={(user?.country ?? country)} />}
                  </div>
                </ContainerCoupon>
              )}

              <PaymentText>{t("premium_landing_view_plan_recurring_billing_title")}</PaymentText>
              <InfoLinks>
                <li>
                  <Link to="/terms-of-use">{t("premium_landing_view_plan_terms_of_use")}</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/privacy-policy">{t("premium_landing_view_plan_privacy")} </Link>
                </li>
              </InfoLinks>
            </div>

            <Footer>
              <img src={require("../assets/logo/white-logo.svg")} alt={""} />
            </Footer>

          </Animate>
        </div>
      )}
    </React.Fragment>
  );
};

const ViewTitle = styled.h1`
  text-align: center;
  margin-bottom: 10px;
  font-size: 2em;
  color: #000;
  font-weight: 700;

  @media (max-width: 1000px) {
    font-size: 1.4em;
    padding: 0px 30px;
  }
`;

const ViewSubTitle = styled.h1`
  text-align: center;
  margin-bottom: 40px;
  font-size: 1em;
  color: #000;
  font-weight: 400;

  @media (max-width: 1000px) {
    margin-bottom: 10px;
    font-size: 1em;
    padding: 0px 30px;
  }
`;

const ViewUserReferralInformation = styled.h3`
  font-size: 1em;
  color: #000;

  @media (max-width: 1000px) {
    padding: 0px 30px;
    margin-top: 10px;
  }
`;

const ContainerCoupon = styled.div`
  @media (max-width: 1000px) {
    text-align: center;
  }

  h1 {
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: 700;
    text-align: left;
    padding-left: 0px;
    text-align: center;

    @media (max-width: 1000px) {
      text-align: center;
      margin-left: 0px;
      padding-right: 0px;
    }
  }
`;

const ContainerPlans = styled.div`
  margin: 0px 20px;

  .espcial-day-1 {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .espcial-day-2 {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .row {
    display: flex;
    justify-content: center;
  }
`;

const PaymentText = styled.p`
  text-align: center;
  font-size: 0.7em;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 0px;
`;

const InfoLinks = styled.ul`
  text-align: center;
  font-size: 0.7em;
  font-weight: 700;
  margin-top: 20px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding-left: 0px;

  @media (max-width: 1000px) {
    padding-left: 0px;
  }

  li {
    cursor: pointer;
    list-style: none;

    a {
      color: #979494 !important;
      font-weight: 500;
    }
  }

  li:nth-child(2) {
    margin-left: 3px;
  }
`;

const Header = styled.div`
  width: 100%;
  border-bottom: 2px solid #e5e5e58c;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    text-align: center;
  }

  img {
    padding: 25px;
    width: 200px;
  }
`;

const Footer = styled.div`
  width: 100%;
  background-color: #2b5283;
  height: 150px;
  text-align: center;
  object-position: center;
  padding-top: 50px;

  img {
    width: 150px;
    text-align: center;
    object-position: center;
    display: flex;
    margin: auto;
  }
`;

const ContCuponTitle = styled.span`
  position: relative;
  width: fit-content;
  margin: auto;

  svg {
    position: absolute;
    right: -10px;
    top: calc(50% - 0.25em);
    cursor: pointer;
    height: 0.9em;
    width: 0.9em;
    color: #203656;
  }
`;

const CuponTitle = styled.h1`
  cursor: pointer;
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  selectedPlan: state.selectedPlan,
  isAppleDevice: state.isAppleDevice,
  country: state.country,
  spotList: state.spotList,
});

export default connect(mapStateToProps)(PremiumLandingView);